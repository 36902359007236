/**
 * @format
 */

import React from 'react';
import * as FieldControl from '../input';
import { CollapsablePanelWithSectionHeader, DisclaimerHtml, TextLiteral } from './miscComponents';

const checkHasDisclaimer = (disclaimerText) => ((disclaimerText && disclaimerText.length) > 0 ? true : false);

const showDisclaimer = (disclaimerText, disclaimerConfig) => {
	const hasDisclaimer = checkHasDisclaimer(disclaimerText);
	const shouldShowDisclaimer = disclaimerConfig.isVisible && disclaimerConfig.value ? true : false;
	return hasDisclaimer && shouldShowDisclaimer;
};

export default function ContactDetailsSection(props) {
	const {
		contactFieldsInfo,
		enableSectionValidationIcons,
		handleInputBlur,
		handleInputChange,
		handleInputFocus,
		handleToggleCollapse,
		hasErrors,
		isCollapsed,
		name,
		notificationConfig,
		reservationDetails,
		sectionConfig,
		shouldShowSectionValidation,
	} = props;

	const {
		emailConfirmationEnabled,
		allowEmailConfirmationOtherEmail,
		patientConfirmationEmailDisclaimer,
		showPatientConfirmationEmailOptIn,
		emailReminderEnabled,
		allowEmailReminderOtherEmail,
		patientReminderEmailDisclaimer,
		textReminderEnabled,
		allowTextReminderOtherPhone,
		patientReminderSmsDisclaimer,
		voiceReminderEnabled,
		allowVoiceReminderOtherPhone,
		patientReminderVoiceDisclaimer,
		emailInstructionsText,
		phoneInstructionsText,
	} = notificationConfig;

	const { headerText, isCollapsable, otherOptionText, patientEmailOptionText, otherEmailOptionText, showHeader } =
		sectionConfig;

	const showNotificationsSection =
		emailConfirmationEnabled || emailReminderEnabled || textReminderEnabled || voiceReminderEnabled;

	const showEmailInstructionsText = contactFieldsInfo.emailConfirmationConsent.value || contactFieldsInfo.emailReminderConsent.value;

	const showTextReminderDisclaimer = showDisclaimer(
		patientReminderSmsDisclaimer,
		contactFieldsInfo.textReminderConsent,
	);

	const showVoiceReminderDisclaimer = showDisclaimer(
		patientReminderVoiceDisclaimer,
		contactFieldsInfo.voiceReminderConsent,
	);

	const showEmailReminderDisclaimer = showDisclaimer(
		patientReminderEmailDisclaimer,
		contactFieldsInfo.emailReminderConsent,
	);

	const showEmailConfirmationDisclaimer =
		emailConfirmationEnabled && !showPatientConfirmationEmailOptIn
			? true
			: showDisclaimer(patientConfirmationEmailDisclaimer, contactFieldsInfo.emailConfirmationConsent);

	const hasMobilePhone = contactFieldsInfo.mobilePhone.value && contactFieldsInfo.mobilePhone.value.length > 0;
	const hasHomePhone = contactFieldsInfo.homePhone.value && contactFieldsInfo.homePhone.value.length > 0;
	const hasEmail = contactFieldsInfo.email.value && contactFieldsInfo.email.value.length > 0;

	const otherConfirmationEmailInstructionsText = !showPatientConfirmationEmailOptIn
		? '<p>Email confirmation will automatically be sent to the provided email address. Optionally, you may override that email below.</p>'
		: '<p>A confirmation email will be sent to your email address. Optionally, you may override that email below.</p>'; //TODO: Make this config

	const otherReminderEmailInstructionsText =
		'<p>Email reminders will be sent to your email address. Optionally, you may override that email below.</p>'; //TODO: Make this config

	const thirdPartyEmailInstructionsText =
		'<p>Optionally, you may elect to forward all email notifications to a third party email address.</p>'; //TODO: Make this config

	const otherSmsRemindersInstructionsText =
		'<p>Text message reminders will be sent to your phone number. Optionally, you may override that phone number below.</p>'; //TODO: Make this config

	const otherVoiceRemindersInstructionsText =
		'<p>Voice reminders will be sent to your phone number. Optionally, you may override that phone number below.</p>'; //TODO: Make this config

	let voiceReminderNumberList = [];
	if (hasHomePhone) voiceReminderNumberList.push({ name: 'Home', id: 'homePhone' });
	if (hasMobilePhone) voiceReminderNumberList.push({ name: 'Mobile', id: 'mobilePhone' });
	if (allowVoiceReminderOtherPhone)
		voiceReminderNumberList.push({ name: otherOptionText, id: 'voiceReminderPhoneOther' });

	let textReminderNumberList = [];
	if (hasMobilePhone) textReminderNumberList.push({ name: 'Mobile', id: 'mobilePhone' });
	if (allowTextReminderOtherPhone) textReminderNumberList.push({ name: otherOptionText, id: 'textReminderPhoneOther' });

	let confirmationEmailList = [];
	if (hasEmail) confirmationEmailList.push({ name: patientEmailOptionText, id: 'email' });
	if (allowEmailConfirmationOtherEmail)
		confirmationEmailList.push({ name: otherEmailOptionText, id: 'confirmationEmailOther' });

	let reminderEmailList = [];
	if (hasEmail) reminderEmailList.push({ name: patientEmailOptionText, id: 'email' });
	if (allowEmailReminderOtherEmail) reminderEmailList.push({ name: otherEmailOptionText, id: 'reminderEmailOther' });

	let notificationLanguageList = [];
	if (reservationDetails.notificationLanguages && reservationDetails.notificationLanguages.length > 0) {
		notificationLanguageList = reservationDetails.notificationLanguages.map((x) => {
			return { name: x.languageName, id: x.idLanguage };
		});
	}

	const showEmailConfirmationSection =
		contactFieldsInfo.emailConfirmationConsent.isVisible ||
		contactFieldsInfo.confirmationEmail.isVisible ||
		contactFieldsInfo.confirmationEmailOther.isVisible ||
		showEmailConfirmationDisclaimer;

	const showEmailRemindersSection =
		contactFieldsInfo.emailReminderConsent.isVisible ||
		contactFieldsInfo.reminderEmail.isVisible ||
		contactFieldsInfo.reminderEmailOther.isVisible ||
		showEmailReminderDisclaimer;

	const showSmsRemindersSection =
		contactFieldsInfo.textReminderConsent.isVisible ||
		contactFieldsInfo.textReminderNumber.isVisible ||
		contactFieldsInfo.textReminderPhoneOther.isVisible ||
		showTextReminderDisclaimer;

	const showVoiceRemindersSection = contactFieldsInfo.voiceReminderConsent.isVisible || showVoiceReminderDisclaimer;
	const showPhoneInstructionsText = contactFieldsInfo.voiceReminderConsent.value || contactFieldsInfo.textReminderConsent.value;

	return (
		<CollapsablePanelWithSectionHeader
			className="main-section"
			enableSectionValidationIcons={enableSectionValidationIcons}
			hasErrors={hasErrors}
			isCollapsed={isCollapsed}
			isCollapsable={isCollapsable}
			label={headerText}
			name={name}
			onToggleCollapse={handleToggleCollapse}
			showHeader={showHeader}
			shouldShowSectionValidation={shouldShowSectionValidation}
		>
			<div className="section-body">
				<div className="section-row">
					<DisclaimerHtml
						className="form-instructions"
						isVisible={!!sectionConfig.contactDetailsConfigInstructions}
						text={sectionConfig.contactDetailsConfigInstructions}
					/>
				</div>
				<div className="section-row">
					<FieldControl.PhoneInput
						{...contactFieldsInfo.mobilePhone}
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						onFocus={handleInputFocus}
					/>
					<FieldControl.PhoneInput
						{...contactFieldsInfo.homePhone}
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						onFocus={handleInputFocus}
					/>
					<FieldControl.PhoneInput
						{...contactFieldsInfo.alternatePhone}
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						onFocus={handleInputFocus}
					/>
				</div>
				<div className="section-row">
					<FieldControl.EmailInput
						{...contactFieldsInfo.email}
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						onFocus={handleInputFocus}
					/>
				</div>
				{showNotificationsSection && (
					<>
						{checkHasDisclaimer(emailInstructionsText) && showEmailInstructionsText && (
							<div className="section-row">
								<DisclaimerHtml
									className="form-instructions"
									isVisible={!!emailInstructionsText}
									text={emailInstructionsText}
								/>
							</div>
						)}
						{(showEmailConfirmationSection || (contactFieldsInfo.emailConfirmationConsent.value && contactFieldsInfo.thirdPartyEmail.isVisible)) &&(
							<div className="section-row">
								{showEmailConfirmationSection && (
									<div className="subsection">
										<div className="subsection-row">
											<FieldControl.CheckboxInput
												{...contactFieldsInfo.emailConfirmationConsent}
												onChange={handleInputChange}
												onBlur={handleInputBlur}
												onFocus={handleInputFocus}
											/>
											<FieldControl.DropdownSelect
												{...contactFieldsInfo.confirmationEmail}
												onChange={handleInputChange}
												onBlur={handleInputBlur}
												onFocus={handleInputFocus}
												optionList={confirmationEmailList}
											/>
											<div className="section-row">
												<DisclaimerHtml
													className="form-instructions"
													isVisible={contactFieldsInfo.confirmationEmailOther.isVisible}
													text={otherConfirmationEmailInstructionsText}
												/>
											</div>
											<FieldControl.EmailInput
												{...contactFieldsInfo.confirmationEmailOther}
												onChange={handleInputChange}
												onBlur={handleInputBlur}
												onFocus={handleInputFocus}
											/>
											{checkHasDisclaimer(patientConfirmationEmailDisclaimer) && (
												<div className="subsection-row">
													<DisclaimerHtml
														isVisible={showEmailConfirmationDisclaimer}
														text={patientConfirmationEmailDisclaimer}
														className="disclaimer"
													/>
												</div>
											)}
										</div>
									</div>
								)}
								{contactFieldsInfo.emailConfirmationConsent.value && contactFieldsInfo.thirdPartyEmail.isVisible && (
									<div className="section-row">
										<div className="subsection">
											<div className="subsection-row">
												<DisclaimerHtml
													isVisible={contactFieldsInfo.thirdPartyEmail.isVisible}
													text={thirdPartyEmailInstructionsText}
													className="disclaimer"
												/>
											</div>
											<div className="subsection-row">
												<FieldControl.EmailInput
													{...contactFieldsInfo.thirdPartyEmail}
													onChange={handleInputChange}
													onBlur={handleInputBlur}
													onFocus={handleInputFocus}
												/>
											</div>
										</div>
									</div>
								)}
							</div>
						)}
						{showEmailRemindersSection && (
							<div className="section-row">
								<div className="subsection">
									<div className="subsection-row">
										<FieldControl.CheckboxInput
											{...contactFieldsInfo.emailReminderConsent}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											onFocus={handleInputFocus}
										/>
										<FieldControl.DropdownSelect
											{...contactFieldsInfo.reminderEmail}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											onFocus={handleInputFocus}
											optionList={reminderEmailList}
										/>
										<div className="section-row">
											<DisclaimerHtml
												className="form-instructions"
												isVisible={contactFieldsInfo.reminderEmailOther.isVisible}
												text={otherReminderEmailInstructionsText}
											/>
										</div>
										<FieldControl.EmailInput
											{...contactFieldsInfo.reminderEmailOther}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											onFocus={handleInputFocus}
										/>
									</div>
									<div className="subsection-row">
										<DisclaimerHtml
											isVisible={showEmailReminderDisclaimer}
											text={patientReminderEmailDisclaimer}
											className="disclaimer"
										/>
									</div>
								</div>
							</div>
						)}
						<div className="section-row">
							<TextLiteral isVisible={true} text={''} />
						</div>
						<div className="section-row">
							<DisclaimerHtml
								className="form-instructions"
								isVisible={!!phoneInstructionsText && showPhoneInstructionsText}
								text={phoneInstructionsText}
							/>
						</div>
						{showSmsRemindersSection && (
							<div className="section-row">
								<div className="subsection">
									<div className="subsection-row">
										<FieldControl.CheckboxInput
											{...contactFieldsInfo.textReminderConsent}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											onFocus={handleInputFocus}
										/>
										<FieldControl.DropdownSelect
											{...contactFieldsInfo.textReminderNumber}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											onFocus={handleInputFocus}
											optionList={textReminderNumberList}
										/>
										<div className="section-row">
											<DisclaimerHtml
												className="form-instructions"
												isVisible={contactFieldsInfo.textReminderPhoneOther.isVisible}
												text={otherSmsRemindersInstructionsText}
											/>
										</div>
										<FieldControl.PhoneInput
											{...contactFieldsInfo.textReminderPhoneOther}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											onFocus={handleInputFocus}
										/>
									</div>
									<div className="subsection-row">
										<DisclaimerHtml
											isVisible={showTextReminderDisclaimer}
											text={patientReminderSmsDisclaimer}
											className="disclaimer"
										/>
									</div>
								</div>
							</div>
						)}
						{showVoiceRemindersSection && (
							<div className="section-row">
								<section className="subsection">
									<div className="subsection-row">
										<FieldControl.CheckboxInput
											{...contactFieldsInfo.voiceReminderConsent}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											onFocus={handleInputFocus}
										/>
										<FieldControl.DropdownSelect
											{...contactFieldsInfo.voiceReminderNumber}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											onFocus={handleInputFocus}
											optionList={voiceReminderNumberList}
										/>
										<div className="section-row">
											<DisclaimerHtml
												className="form-instructions"
												isVisible={contactFieldsInfo.voiceReminderPhoneOther.isVisible}
												text={otherVoiceRemindersInstructionsText}
											/>
										</div>
										<FieldControl.PhoneInput
											{...contactFieldsInfo.voiceReminderPhoneOther}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											onFocus={handleInputFocus}
										/>
									</div>
									<div className="subsection-row">
										<DisclaimerHtml
											isVisible={showVoiceReminderDisclaimer}
											text={patientReminderVoiceDisclaimer}
											className="disclaimer"
										/>
									</div>
								</section>
							</div>
						)}
						{notificationLanguageList.length > 1 && (
							<div className="section-row">
								<FieldControl.DropdownSelect
									{...contactFieldsInfo.notificationLanguage}
									onChange={handleInputChange}
									onBlur={handleInputBlur}
									onFocus={handleInputFocus}
									optionList={notificationLanguageList}
								/>
							</div>
						)}
					</>
				)}
			</div>
		</CollapsablePanelWithSectionHeader>
	);
}
