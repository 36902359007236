import React from 'react';
import { useRoutes } from 'react-router-dom';
import { AppRouteObject } from './types';
import ProtectedRoute from './protectedRoute';
 
export const RouteRenderer: React.FC<React.PropsWithChildren<{ routes: AppRouteObject[] }>> = ({ routes }) => {
    const generateRoutes = (routes: AppRouteObject[]) =>
        routes.flatMap((route) => {
            const { path, element, permission, publicAccess = false } = route;
 
            const WrappedElement = publicAccess ? (
                element
            ) : (
                <ProtectedRoute element={element} permission={permission} />
            );
 
            const paths = Array.isArray(path) ? path : [path];
            return paths.map((singlePath) => ({
                path: singlePath,
                element: WrappedElement,
            }));
        });
 
    const routing = useRoutes(generateRoutes(routes));
    return <>{routing}</>;
};
 
export default RouteRenderer;