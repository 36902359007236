import * as actionTypes from './actionTypes';

export function processDecisionSupportSubpoints(subpoints, endedInGuidedResponse) {
	return async function (dispatch, getState) {
		await dispatch(processDecisionSupportSubpointsAction(subpoints, endedInGuidedResponse))
		const state = getState();
		return Promise.resolve({
			activeCareOrder: state.careOrder,
			decisionSupportOutput: state.decisionSupport,
			correlationKey: state.session.correlationKey,
			availabilitySearchConfig: state.config.availabilitySearch,
			patientDetails: state.patient
		});
	};
}

function processDecisionSupportSubpointsAction(subpoints, endedInGuidedResponse) {
	return {
		type: actionTypes.PROCESS_DECISION_SUPPORT_SUBPOINTS,
		subpoints: subpoints,
		endedInGuidedResponse: endedInGuidedResponse
	};
}
