/**
 * @format
 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import {
	getReservationDetails,
	getCustomFields,
	getReferringProviders,
	getOrgReferringProviders,
	searchOrgProviders,
	searchReferringProviders,
	bookAppointmentWithNewPatient,
	cancelReservation,
	searchAddress,
	getAddress
} from '../../actions/appointmentActions';
import { clearAllErrors } from '../../actions/errorActions';
import { INPUT_TYPES } from '../../constants/inputTypes';

import {
	getInitialFieldsState,
	getFieldGroupsInfoFromFieldsConfig,
	getPreBookCustomFieldsInfo,
	getPostBookCustomFieldsInfo,
} from '../../lib/fieldConfigUtils';

import { buildFieldRefFromId, buildFieldRefFromParts } from '../../lib/fieldUtils';
import * as validationUtils from '../../lib/validationUtils';
import { deepCopy, resolveGenderList, scrollToPageTop } from '../../lib/misc';
import { DisclaimerHtml } from '../../components/misc/miscComponents';
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';
import { ConditionsOfAccessModal } from './comps/conditionsOfAccessModal';
import { PostBookAppointmentCustomFieldsForm } from './comps/postBookAppointmentCustomFieldsForm';
import AppointmentSummaryPanel from '../../components/appointment/appointmentSummaryPanel';
import BookingProcessPanel from '../../components/misc/bookingProcessPanel';
import ContactDetailsSection from '../../components/misc/contactDetailsSection';
import PatientDetailsSection from '../../components/misc/patientDetailsSection';
import ReferringProviderSection from '../../components/misc/referringProviderSection';
import * as Button from '../../components/buttons';
import SymptomDetailsSection from '../../components/misc/symptomDetailsSection';
import ConditionsOfAccessSection from '../../components/misc/conditionsOfAccessSection';
import EarlierAppointmentRequestSection from '../../components/misc/earlierAppointmentRequestSection';
import CustomFieldsSection from '../../components/misc/customFieldsSection';
import ActiveErrors from '../../components/misc/activeErrors';
import * as routes from '../../routes';
import * as mapper from './bookAppointmentMapper';
import { withRouter } from 'hoc';
import {
	getFieldsConfig,
	getPostBookCustomFieldsConfig,
	generateCustomFieldsComps,
	hasVisiblePostBookCustomFields,
} from './bookAppointment';
import CountDownTimer from '../../components/misc/countDownTimer'

const MAX_MOBILE_WIDTH = 992;

const ORDERED_SECTIONS = [
	'patient',
	'contactInfo',
	'symptomDetails',
	'referringProvider',
	'customFieldsPreBook',
	'customFieldsPostBook',
];

export class BookAppointmentView extends React.Component {
	constructor(props) {
		super(props);

		const useSampleValues = false; // TODO: set to false before final PR

		this.fieldsConfig = getFieldsConfig(props);
		this.postBookCustomFieldsConfig = getPostBookCustomFieldsConfig(props);

		this.state = {
			collapse: {
				appointmentSummary: true, // NOTE: this bit is slightly misleading, it isn't really "collapsed" like the others in desktop, only in mobile version
				contactDetails: false,
				patientDetails: false,
				referringProvider: false,
				symptomDetails: false,
			},
			errors: [],
			fields: getInitialFieldsState(this.fieldsConfig, this.postBookCustomFieldsConfig, props, useSampleValues),
			isBooking: false,
			isChangingAppointment: false,
			isCustomFieldsLoading: true,
			isDetailsLoading: false,
			isFocusedLookup: {},
			isMobile: false,
			isPostBookAppointment: false,
			isReservation: true,
			isServerTypeAheadLoading: false,
			lastTouchedSection: null,
			shouldShowSectionValidation: {},
			showConditionsOfAccessModal: false,
			showConditionsOfAccessWarning: false,
			isConditionsOfAccessAccepted: false,
			isEarlierAppointmentRequested: false,
			isReservationExpired: false,
			isAddressLoading: false,
			addressInputValue: '',
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.resize.bind(this));
		this.resize();
		scrollToPageTop();
		if (this.props.reservation.flexCalendarEntryId) {
			this.loadDetails(this.props);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.fieldsConfig = getFieldsConfig(nextProps);
		this.postBookCustomFieldsConfig = getPostBookCustomFieldsConfig(nextProps);
		if (nextProps.reservation.flexCalendarEntryId !== this.props.reservation.flexCalendarEntryId) {
			this.loadDetails(nextProps);
		}
	}

	resize() {
		this.setState({ isMobile: window.innerWidth <= MAX_MOBILE_WIDTH });
	}

	doesSectionHaveErrors = (fieldGroupName, errors) => {
		let sectionErrors = errors.filter((error) => error.fieldGroupName === fieldGroupName);
		if (sectionErrors.length > 0) return true;
		else return false;
	};

	getOrderedFormFailingErrorSections = (failingErrors) => {
		let errorSections = [];
		ORDERED_SECTIONS.forEach((x) => {
			if (this.doesSectionHaveErrors(x, failingErrors)) errorSections.push(x);
		});
		return errorSections;
	};

	getFirstFormFailingErrorSectionId = (failingErrors) => {
		const errorSections = this.getOrderedFormFailingErrorSections(failingErrors);
		if (errorSections.length > 0) {
			return errorSections[0]; //first error section
		} else {
			return null;
		}
	};

	toggleConditionsOfAccessModal = (e) => {
		e.preventDefault();
		let showModal = this.state.showConditionsOfAccessModal;
		this.setState({ showConditionsOfAccessModal: !showModal });
	};

	togglePostBookModal = (e, forceClose) => {
		let target = e.target;
		if (forceClose || (this.state.isPostBookAppointment && target.id === 'postBookCustomFieldsModalContainer')) {
			this.setState({ isPostBookAppointment: false });
		}
	};

	loadDetails = (props) => {
		let serviceSiteId = props.reservation.timeSlotRequestInfo.serviceSiteId;
		let insuranceId = props.decisionSupport.availabilitySearchCriteria.insuranceProviderId;
		let payorTypeId = props.decisionSupport.availabilitySearchCriteria.payorTypeId;
		let defaultLanguage = props.schedulingConfig.languageId;
		let useReferringProviders = props.referringProviderConfig.useReferringProviders;
		let loadInternalReferringProviders =
			useReferringProviders && props.referringProviderConfig.allowInternalReferringProviders;
		let loadOrgReferringProviders = useReferringProviders && props.referringProviderConfig.allowOrgReferringProviders;
		let enableServerTypeAhead = useReferringProviders && props.referringProviderConfig.enableServerTypeAhead;
		let patientReferenceId = props.patient.patientReferenceId;
		this.setState({ isDetailsLoading: true }, () => {
			let reservationDetails = props.getReservationDetails(
				props.reservation.flexCalendarEntryId,
				serviceSiteId,
				insuranceId,
				payorTypeId,
				patientReferenceId,
			);
			let customFieldConfig = this.props.getCustomFields(this.props.referralSystemId);

			Promise.all([reservationDetails, customFieldConfig])
				.then((responses) => {
					const reservationResponse = responses[0];
					const customFieldResponse = responses[1];
					if (reservationResponse.error || customFieldResponse.error) {
						this.setState({
							isDetailsLoading: false,
							isCustomFieldsLoading: false,
						});
						scrollToPageTop();
					} else {
						let fields = deepCopy(this.state.fields);
						let customFields = this.props.customFields;

						//RESERVATION STUFF
						let setLanguage = '';
						const notificationLanguages = reservationResponse.payload.data.notificationLanguages;
						if (notificationLanguages && notificationLanguages.length === 1) {
							setLanguage = reservationResponse.payload.data.notificationLanguages[0].idLanguage;
						} else if (notificationLanguages && notificationLanguages.find((x) => x.idLanguage === defaultLanguage)) {
							setLanguage = defaultLanguage;
						}
						fields.contactInfo.notificationLanguage = setLanguage;
						let isConditionsOfAccessAccepted = !props.viewConfig.sections.conditionsOfAccessDetails.isEnabled;

						let preBookAnswers = {};
						let postBookAnswers = {};
						let decisionSupportAnswers = {};

						let preBookCustomFields = customFields.customFields.filter((x) => {
							return x.isPostBookAppointment === false && x.printIt === true;
						});
						let postBookCustomFields = customFields.customFields.filter((x) => {
							return x.isPostBookAppointment === true && x.printIt === true;
						});
						let decisionSupportCustomFields = customFields.customFields.filter((x) => {
							return x.isActive === true && x.printIt === false;
						});

						if (preBookCustomFields.length) {
							preBookCustomFields.forEach((field) => {
								if (customFields.customFieldAnswers.length) {
									let answerMatch = customFields.customFieldAnswers.find((answer) => {
										return answer.key.localeCompare(field.fieldLabel, undefined, { sensitivity: 'accent' }) === 0;
									});
									preBookAnswers[field.fieldName] = answerMatch ? answerMatch.value : '';
								}
							});
						}

						if (postBookCustomFields.length) {
							postBookCustomFields.forEach((field) => {
								if (customFields.customFieldAnswers.length) {
									let answerMatch = customFields.customFieldAnswers.find((answer) => {
										return answer.key.localeCompare(field.fieldLabel, undefined, { sensitivity: 'accent' }) === 0;
									});
									postBookAnswers[field.fieldName] = answerMatch ? answerMatch.value : '';
								}
							});
						}

						if (decisionSupportCustomFields.length) {
							decisionSupportCustomFields.forEach((field) => {
								if (customFields.customFieldAnswers.length) {
									let answerMatch = customFields.customFieldAnswers.find((answer) => {
										return answer.key.localeCompare(field.fieldLabel, undefined, { sensitivity: 'accent' }) === 0;
									});
									decisionSupportAnswers[field.fieldName] = answerMatch ? answerMatch.value : '';
								}
							});
						}

						const patientDemographics = this.props.patient.demographics;

						fields.customFieldsPreBook = preBookAnswers;
						fields.customFieldsPostBook = postBookAnswers;
						fields.customFieldsDecisionSupport = decisionSupportAnswers;

						fields.contactInfo.email = patientDemographics.email ? patientDemographics.email : '';
						fields.contactInfo.homePhone = patientDemographics.homePhone ? patientDemographics.homePhone : '';
						fields.contactInfo.mobilePhone = patientDemographics.mobilePhone ? patientDemographics.mobilePhone : '';

						fields.patient.addressLine1 = patientDemographics.addressLine1 ? patientDemographics.addressLine1 : '';
						fields.patient.addressLine2 = patientDemographics.addressLine2 ? patientDemographics.addressLine2 : '';
						fields.patient.cityName = patientDemographics.cityName ? patientDemographics.cityName : '';
						fields.patient.dateOfBirth = patientDemographics.dateOfBirth
							? moment.utc(patientDemographics.dateOfBirth).format('MM/DD/YYYY')
							: '';
						fields.patient.firstName = patientDemographics.firstName ? patientDemographics.firstName : '';
						fields.patient.gender = patientDemographics.gender ? patientDemographics.gender : '';
						fields.patient.groupNumber = patientDemographics.groupNumber ? patientDemographics.groupNumber : '';
						fields.patient.lastName = patientDemographics.lastName ? patientDemographics.lastName : '';
						fields.patient.memberId = patientDemographics.memberId ? patientDemographics.memberId : '';
						fields.patient.middleName = patientDemographics.middleName ? patientDemographics.middleName : '';
						fields.patient.parentOrGuardianName = patientDemographics.parentOrGuardianName
							? patientDemographics.parentOrGuardianName
							: '';
						fields.patient.zipCode = patientDemographics.zipCode ? patientDemographics.zipCode : '';
						let patientStateId = this.props.patientFieldConfig.idPgmStateDefault || '';
						const patientState = patientDemographics.stateCode ? props.stateList.find((x) => x.code === patientDemographics.stateCode) : null;
						if (patientState) {
							patientStateId = patientState.id;
						}
						fields.patient.stateId = patientStateId;


						this.setState({
							isCustomFieldsLoading: false,
							isDetailsLoading: false,
							selectedLanguage: setLanguage,
							isReservation: true,
							isConditionsOfAccessAccepted: isConditionsOfAccessAccepted,
							fields: fields,
							unmanagedReferringServiceName: this.props.unmanagedReferringServiceName,
						});
						scrollToPageTop();
					}
				})
				.catch((err) => {
					this.setState({
						isDetailsLoading: false,
						isCustomFieldsLoading: false,
					});
					scrollToPageTop();
				});
		});

		if (loadInternalReferringProviders && !enableServerTypeAhead) {
			props.getReferringProviders(props.referralSystemId);
		}

		if (loadOrgReferringProviders && !enableServerTypeAhead) {
			props.getOrgReferringProviders(null);
		}
	};

	// TODO: Componitize
	getDirections = (e) => {
		e.preventDefault();
		let details = this.props.reservationDetails;
		let sourceAddress = 'adr. ';
		let destinationAddress =
			'adr.' +
			details.providerAddressLine1 +
			' ' +
			details.providerAddressLine2 +
			' ' +
			details.providerCity +
			', ' +
			details.providerState +
			' ' +
			details.providerZip;
		const baseUrl = 'http://bing.com/maps/default.aspx';
		let fullUrl = baseUrl + '?rtp=' + sourceAddress + '~' + destinationAddress;
		window.open(fullUrl);
	};

	handleToggleCollapse = (name) => {
		let collapse = { ...this.state.collapse };
		let errors = this.state.errors;
		let shouldShowSectionValidation = { ...this.state.shouldShowSectionValidation };
		if (!collapse[name]) {
			shouldShowSectionValidation[name] = true;
			errors = validationUtils.validateSection(
				name,
				this.state.errors,
				this.state.fields,
				this.props,
				this.fieldsConfig,
			);
		}
		collapse[name] = !collapse[name];
		this.setState({ collapse, errors, shouldShowSectionValidation });
	};

	handleInputFocus = (e) => {
		let fieldRef = buildFieldRefFromId(e.target.name);
		let fieldGroupName = fieldRef.fieldGroupName;
		let lastTouchedSection = this.state.lastTouchedSection;
		let shouldShowSectionValidation = { ...this.state.shouldShowSectionValidation };
		let errors = this.state.errors;
		if (lastTouchedSection && this.state.lastTouchedSection !== fieldGroupName) {
			shouldShowSectionValidation[lastTouchedSection] = true;
			errors = validationUtils.validateSection(
				lastTouchedSection,
				this.state.errors,
				this.state.fields,
				this.props,
				this.fieldsConfig,
			);
		}
		let isFocusedLookup = {};
		isFocusedLookup[e.target.name] = true;
		this.setState({ errors, isFocusedLookup, lastTouchedSection: fieldGroupName, shouldShowSectionValidation });
	};

	handleInputBlur = (e) => {
		let isFocusedLookup = { ...this.state.isFocusedLookup };
		delete isFocusedLookup[e.target.name];
		this.setState({ isFocusedLookup });
	};

	// TODO: evaluate and decide on best method for handleInputChange
	handleInputChange = (e, isPostBookAppointment) => {
		let fieldsConfig = isPostBookAppointment ? this.postBookCustomFieldsConfig : this.fieldsConfig;
		let fieldRef = buildFieldRefFromId(e.target.name);
		let fieldConfig = fieldsConfig[fieldRef.fieldGroupName][fieldRef.fieldName];
		let fieldValues = deepCopy(this.state.fields);
		let fieldGroup = { ...fieldValues[fieldRef.fieldGroupName] };

		// TODO: consider changing checkbox to raise "final value" instead of counting on a "toggle" behavior
		if (fieldConfig.inputType === INPUT_TYPES.CHECKBOX) {
			fieldGroup[fieldRef.fieldName] = !fieldGroup[fieldRef.fieldName];
		} else if (fieldConfig.inputType === INPUT_TYPES.RADIO_OPTIONS) {
			fieldGroup[fieldRef.fieldName] = e.target.value;
		} else {
			fieldGroup[fieldRef.fieldName] = e.target.value;
		}

		fieldValues[fieldRef.fieldGroupName] = fieldGroup;

		this.setState(
			{
				fields: fieldValues,
			},
			() => {
				const errors = validationUtils.validateField(
					this.state.errors,
					this.state.fields,
					this.props,
					fieldsConfig,
					fieldRef,
				);
				this.setState({ errors });
			},
		);
	};

	handlePostBookInputChange = (e) => {
		this.handleInputChange(e, true);
	};

	handleReferringProviderServerTypeAheadSearch = (query, isOrgProvider) => {
		this.setState({ isServerTypeAheadLoading: true });
		if (isOrgProvider) {
			this.props.searchOrgProviders(query).then(() => {
				this.setState({ isServerTypeAheadLoading: false });
			});
		} else {
			this.props.searchReferringProviders(this.props.referralSystemId, query).then(() => {
				this.setState({ isServerTypeAheadLoading: false });
			});
		}
	};

	handleOrgReferringProviderChange = (e) => {
		if (e.target.orgReferringProvider) {
			this.setState({ referringOrganizationProviderId: e.target.value });
		} else {
			this.setState({ referringOrganizationProviderId: e.currentTarget.value });
		}
		this.handleInputChange(e);
	};

	handleReferringProviderChange = (e) => {
		if (e.currentTarget.options) {
			this.setState({
				internalReferringServiceId: e.currentTarget.value,
			});
		} else {
			this.setState({
				unmanagedReferringServiceName: e.currentTarget.value,
			});
		}
		this.handleInputChange(e);
	};

	handleReferringProviderChangeTypeAhead = (e) => {
		this.setState({ internalReferringServiceId: e.target.value });
		this.handleInputChange(e);
	}


	setReferringProviderIdFromTypeahead = (selected, isOrgReferringProvider) => {
		const e = {
			target: {
				value: 0,
				name: isOrgReferringProvider ? 'referringProvider__orgReferringProvider' : 'referringProvider__internalReferringProvider',
				orgReferringProvider: isOrgReferringProvider,
			},
		};
		if (selected && selected.length > 0) {
			e.target.value = isOrgReferringProvider ? selected[0].organizationProviderId : selected[0].serviceId;
		}
		if (isOrgReferringProvider) {
			this.handleOrgReferringProviderChange(e);
		} else {
			this.handleReferringProviderChangeTypeAhead(e);
		}
	};

	handleRequestEarlierAppointmentChange = () => {
		let isEarlierAppointmentRequested = this.state.isEarlierAppointmentRequested;
		this.setState({ isEarlierAppointmentRequested: !isEarlierAppointmentRequested });
	};

	handleConditionsOfAccessChange = () => {
		let isConditionsOfAccessAccepted = this.state.isConditionsOfAccessAccepted;
		this.setState({ isConditionsOfAccessAccepted: !isConditionsOfAccessAccepted });
	};

	// NOTE: the concept of "non-form failing errors" is basically cases where a field is in an invalid state, but doesn't currently apply, so shouldn't prevent form submission
	// EXAMPLE: a text box who's remembered value is invalid, but the textbox is hidden (ie doesn't apply currently).
	// SPECIFIC EXAMPLE: the "Other" reminder email box being an invalid email, but the user then turns off reminders, or picks to use the patient's email instead of the other email
	getFormFailingErrors = (errors) => {
		let formFailingErrors = [];
		let fieldsConfig = this.state.isPostBookAppointment ? this.postBookCustomFieldsConfig : this.fieldsConfig;
		for (let error of errors) {
			const fieldConfig = fieldsConfig[error.fieldGroupName][error.fieldName];
			const validationAppliesFunc = fieldConfig.validationApplies;
			if (!validationAppliesFunc || validationAppliesFunc(errors, this.state.fields, this.fieldsConfig, this.props)) {
				formFailingErrors.push(error);
			}
		}
		return formFailingErrors;
	};

	expandSectionsWithErrors = (failingErrors) => {
		let newCollapse = { ...this.state.collapse };
		let errorSections = this.getOrderedFormFailingErrorSections(failingErrors);
		errorSections.forEach((x) => {
			newCollapse[x] = false;
		});
		return newCollapse;
	};

	setShouldShowAllSectionValidations = () => {
		let newShouldShowSectionValidation = { ...this.state.shouldShowSectionValidation };
		ORDERED_SECTIONS.forEach((x) => {
			newShouldShowSectionValidation[x] = true;
		});
		return newShouldShowSectionValidation;
	};

	handleScrollToFirstError = (formFailingErrors) => {
		let firstFailingSectionId = this.getFirstFormFailingErrorSectionId(formFailingErrors);
		let newCollapse = this.expandSectionsWithErrors(formFailingErrors);
		this.setState({ isBooking: false, collapse: newCollapse });
		document.getElementById(firstFailingSectionId).scrollIntoView({ behavior: 'smooth' });
	};

	handlePostBookModalConfirmation = (e) => {
		this.handleConfirmAppointment(e, true);
	};

	// TODO: think about overall state management and error handling ...
	handleConfirmAppointment = (e, isPostBookAppointment) => {
		e.preventDefault();
		this.props.clearAllErrors();
		if (this.props.config.decisionSupport.useDecisionSupport && (!this.props.decisionSupportSessionId && !this.props.activeCareOrderDetails?.appointments)) {
			let errors = this.state.errors;
			errors.push(
				'Cannot book an appointment without a valid decision support session id or care order id. Please call your provider to schedule.',
			);
			this.setState({
				isBooking: false,
				errors,
			});
		} else {
			let shouldShowSectionValidation = this.setShouldShowAllSectionValidations();
			let fieldsConfig = isPostBookAppointment ? this.postBookCustomFieldsConfig : this.fieldsConfig;
			const errors = validationUtils.validateForm(this.state.errors, this.state.fields, this.props, fieldsConfig);
			this.setState({ errors, isBooking: true, shouldShowSectionValidation });
			let formFailingErrors = this.getFormFailingErrors(errors);
			if (formFailingErrors.length) {
				if (!this.state.isPostBookAppointment) {
					this.handleScrollToFirstError(formFailingErrors);
				}
				this.setState({ isBooking: false });
			} else if (!this.state.isConditionsOfAccessAccepted) {
				this.setState({ showConditionsOfAccessWarning: true, isBooking: false });
			} else if (
				this.props.viewConfig.sections.customFieldsSection.isEnabled &&
				hasVisiblePostBookCustomFields(this.postBookCustomFieldsConfig.customFieldsPostBook) &&
				!this.state.isPostBookAppointment
			) {
				this.setState({ isPostBookAppointment: true, isBooking: false });
			} else {
				const confirmDto = mapper.mapViewInfoToConfirmAppointmentDto(this.state, this.props);
				this.props
					.bookAppointmentWithNewPatient(confirmDto.token, confirmDto.details, confirmDto.savePatientCriteria)
					.then((response) => {
						if (response.error) {
							this.setState({
								isBooking: false,
								isPostBookAppointment: false,
							});
							scrollToPageTop();
						} else {
							if (!response.payload.data.isAppointmentBooked) {
								this.resolveSaveErrors(response.payload.data.messages);
							} else {
								this.props.dispatch(
									routes.appointmentDetails(response.payload.data.referenceId, this.props.config.instance.routePrefix),
								);
								this.setState({
									isBooking: false,
									isPostBookAppointment: false,
								});
							}
						}
					})
					.catch((err) => {
						this.setState({
							isBooking: false,
							isPostBookAppointment: false,
						});
						scrollToPageTop();
					});
			}
		}
	};

	resolveSaveErrors = (messages) => {
		if (messages.length > 0 && messages.includes('zipcode')) {
			let fieldRef = buildFieldRefFromParts('patient', 'zipCode');
			let errors = this.state.errors.filter((x) => x.fieldId !== fieldRef.fieldId);
			let newError = validationUtils.buildError(fieldRef, '', validationUtils.DEFAULT_ERROR('Zip Code'));
			errors.push(newError);

			this.setState({
				errors,
				isBooking: false,
			});

			this.handleScrollToFirstError(errors);
		}
	};

	handleChangeAppointmentClick = () => {
		this.props.clearAllErrors();
		this.setState({ isChangingAppointment: true });
		this.props
			.cancelReservation(this.props.token, this.props.reservation.reservationId)
			.then((response) => {
				if (response.error) {
					this.setState({
						isChangingAppointment: false,
					});
					scrollToPageTop();
				} else {
					this.props.navigate(-1);
				}
			})
			.catch((err) => {
				this.setState({
					isChangingAppointment: false,
				});
				scrollToPageTop();
			});
	};

	handleReservationExpired = () => {
		this.setState({ isReservationExpired: true });
	}

	handleAddressChange = (possibleOption) => {
		if (possibleOption?.value) {
			this.setState({ isAddressLoading: true })
			const addressSearchCriteria = {
				query: possibleOption.label,
			};
			this.props.getAddress(addressSearchCriteria)
				.then(response => {
					const newFields = this.createNewFieldsForAddress(response.payload.data);
					this.setState({
						isAddressLoading: false,
						fields: newFields,
					});
				});
		}
	}

	handleClearPatientField = (fieldToUpdate) => {
		const fields = deepCopy(this.state.fields);
		fields.patient[fieldToUpdate] = "";

		this.setState({
			fields: fields
		})
	}

	createNewFieldsForAddress = (addressSearchResult) => {
		const {
			addressLine1,
			addressLine2,
			city,
			state: stateCode,
			zipCode
		} = addressSearchResult;

		const state = this.props.stateList.find((x) => x.code === stateCode);

		const newFields = deepCopy(this.state.fields);
		newFields.patient.addressLine1 = addressLine1;
		newFields.patient.addressLine2 = addressLine2;
		newFields.patient.cityName = city;
		newFields.patient.zipCode = zipCode.substring(0, 5);
		newFields.patient.stateId = state?.id;

		return newFields;
	}

	loadAddressOptions = async (inputValue, callback) => {
		this.setState({ addressInputValue: inputValue });
		if (inputValue.length >= 8) {
			const result = await this.props.searchAddress(this.state.addressInputValue);
			if (result.error) {
				console.error(result.error, this.state.addressInputValue);
				callback([]);
				return;
			}
			const addressSearchResults = result.payload.data;
			const addressOptions = addressSearchResults.map(a => ({ label: a.address, value: a }));
			callback(addressOptions);
		}
		else {
			callback([]);
			return;
		}
	}

	render() {
		let fieldGroupsInfo = getFieldGroupsInfoFromFieldsConfig(
			this.state.errors,
			this.state.fields,
			this.fieldsConfig,
			this.props,
			this.state.isFocusedLookup,
		);

		let preBookCustomFieldsInfo = null;
		let postBookCustomFieldsInfo = null;
		let preBookCustomFieldsComps = null;
		let postBookCustomFieldsComps = null;

		if (this.props.viewConfig.sections.customFieldsSection.isEnabled && this.fieldsConfig.customFieldsPreBook) {
			preBookCustomFieldsInfo = getPreBookCustomFieldsInfo(
				this.state.errors,
				this.state.fields,
				this.fieldsConfig.customFieldsPreBook,
				this.props,
				this.state.isFocusedLookup,
			);
			if (preBookCustomFieldsInfo) {
				preBookCustomFieldsComps = generateCustomFieldsComps(
					preBookCustomFieldsInfo,
					this.handleInputChange,
					this.handleInputFocus,
					this.handleInputBlur,
				);
			}
		}

		if (
			this.props.viewConfig.sections.customFieldsSection.isEnabled &&
			this.postBookCustomFieldsConfig.customFieldsPostBook
		) {
			postBookCustomFieldsInfo = getPostBookCustomFieldsInfo(
				this.state.errors,
				this.state.fields,
				this.postBookCustomFieldsConfig.customFieldsPostBook,
				this.props,
				this.state.isFocusedLookup,
			);
			if (postBookCustomFieldsInfo) {
				postBookCustomFieldsComps = generateCustomFieldsComps(
					postBookCustomFieldsInfo,
					this.handlePostBookInputChange,
					this.handleInputFocus,
					this.handleInputBlur,
				);
			}
		}

		let formFailingErrors = this.getFormFailingErrors(this.state.errors);
		let patientSectionHasErrors = this.doesSectionHaveErrors('patient', formFailingErrors);
		let contactSectionHasErrors = this.doesSectionHaveErrors('contactInfo', formFailingErrors);
		let referringProviderSectionHasErrors = this.doesSectionHaveErrors('referringProvider', formFailingErrors);
		let symptomSectionHasErrors = this.doesSectionHaveErrors('symptomDetails', formFailingErrors);
		let customSectionHasErrors = this.doesSectionHaveErrors('customFieldsPreBook', formFailingErrors);

		let disableButtons =
			this.state.isDetailsLoading ||
			this.state.isChangingAppointment ||
			this.state.isBooking ||
			this.state.isCustomFieldsLoading;

		let enableConditionsOfAccess = this.props.viewConfig.sections.conditionsOfAccessDetails.isEnabled;
		let enableReferringProviders = this.props.viewConfig.sections.referringProvider.isEnabled;

		let allowInternalReferringProviders =
			this.props.viewConfig.sections.referringProvider.allowInternalReferringProviders;
		let allowExternalReferringProviders =
			this.props.viewConfig.sections.referringProvider.allowExternalReferringProviders;
		let allowOrgReferringProviders = this.props.viewConfig.sections.referringProvider.allowOrgReferringProviders;

		let genderList = resolveGenderList(
			this.props.patientFieldConfig.allowUnknownGender,
			this.props.patientFieldConfig.unknownGenderOptionText,
		);

		const mainContentClassName = classNames('main-content', { 'no-padding-bottom': this.state.collapse.appointmentSummary });

		return (
			<div className="view-content book-appointment-view">
				<ActiveErrors errorLink="/availability" errorLinkText="Please select a different slot." />
				{disableButtons && <LoadingIndicatorModal />}
				{this.state.isPostBookAppointment && (
					<PostBookAppointmentCustomFieldsForm
						handlePostBookModalConfirmation={this.handlePostBookModalConfirmation}
						postBookCustomFields={postBookCustomFieldsComps}
						togglePostBookModal={this.togglePostBookModal}
						isLoading={disableButtons}
						name={'customFieldsPostBook'}
					/>
				)}
				<div className="content-area">
					<aside className={'additional-content'}>
						<AppointmentSummaryPanel
							appointment={this.props.reservationDetails}
							disableButtons={disableButtons}
							getDirections={this.getDirections}
							handleToggleCollapse={this.handleToggleCollapse}
							isChangingAppointment={this.state.isChangingAppointment}
							isCollapsed={this.state.collapse.appointmentSummary}
							isLoading={this.state.isDetailsLoading}
							isMobile={this.state.isMobile}
							mode="Reservation"
							name="appointmentSummary"
							onChangeAppointmentClick={this.handleChangeAppointmentClick}
							providerFieldConfig={this.props.providerFieldConfig}
							summaryConfig={this.props.viewConfig.sections.appointmentSummary}
							fieldConfig={this.props.fieldConfig}
							reservationExpirationTime={moment.utc(this.props.reservation.reservationExpirationTimeString).local().format()}
							handleReservationExpired={this.handleReservationExpired}
						/>
						{this.props.processWorkflow.showProcessPanel && (
							<BookingProcessPanel
								steps={this.props.processWorkflow.steps}
								currentStep={this.props.processWorkflow.currentStepName}
							/>
						)}
					</aside>
					<main className={mainContentClassName}>
						<form id="book-appointment" onSubmit={this.handleConfirmAppointment} disabled={!this.state.isConditionsOfAccessAccepted}>
							<PatientDetailsSection
								genderList={genderList}
								enableSectionValidationIcons={this.props.enableSectionValidationIcons}
								handleInputBlur={this.handleInputBlur}
								handleInputChange={this.handleInputChange}
								handleInputFocus={this.handleInputFocus}
								handleToggleCollapse={this.handleToggleCollapse}
								hasErrors={patientSectionHasErrors}
								isCollapsed={this.state.collapse.patient}
								name="patient"
								patientFieldsInfo={fieldGroupsInfo.patient}
								sectionConfig={this.props.viewConfig.sections.patientDetails}
								shouldShowSectionValidation={this.state.shouldShowSectionValidation.patient}
								stateList={this.props.stateList}
								loadAddressOptions={this.loadAddressOptions}
								showAddressSearch={this.props.schedulingConfig.showAddressSearch}
								handleAddressChange={this.handleAddressChange}
								handleClearPatientField={this.handleClearPatientField}
								isAddressLoading={this.state.isAddressLoading}
							/>
							<ContactDetailsSection
								contactFieldsInfo={fieldGroupsInfo.contactInfo}
								enableSectionValidationIcons={this.props.enableSectionValidationIcons}
								handleInputBlur={this.handleInputBlur}
								handleInputChange={this.handleInputChange}
								handleInputFocus={this.handleInputFocus}
								handleToggleCollapse={this.handleToggleCollapse}
								hasErrors={contactSectionHasErrors}
								isCollapsed={this.state.collapse.contactInfo}
								name="contactInfo"
								notificationConfig={this.props.schedulingConfig.notification}
								reservationDetails={this.props.reservationDetails}
								sectionConfig={this.props.viewConfig.sections.contactDetails}
								shouldShowSectionValidation={this.state.shouldShowSectionValidation.contactInfo}
							/>
							<SymptomDetailsSection
								enableSectionValidationIcons={this.props.enableSectionValidationIcons}
								handleInputBlur={this.handleInputBlur}
								handleInputChange={this.handleInputChange}
								handleInputFocus={this.handleInputFocus}
								handleToggleCollapse={this.handleToggleCollapse}
								hasErrors={symptomSectionHasErrors}
								isCollapsed={this.state.collapse.symptomDetails}
								name="symptomDetails"
								sectionConfig={this.props.viewConfig.sections.symptomDetails}
								shouldShowSectionValidation={this.state.shouldShowSectionValidation.symptomDetails}
								symptomFieldsInfo={fieldGroupsInfo.symptomDetails}
							/>
							{enableReferringProviders && (
								<ReferringProviderSection
									enableSectionValidationIcons={this.props.enableSectionValidationIcons}
									enableServerTypeAhead={this.props.referringProviderConfig.enableServerTypeAhead}
									handleInputBlur={this.handleInputBlur}
									handleInputChange={this.handleReferringProviderChange}
									handleInputFocus={this.handleInputFocus}
									handleToggleCollapse={this.handleToggleCollapse}
									serverTypeAheadMinimumCharacters={this.props.referringProviderConfig.serverTypeAheadMinimumCharacters}
									hasErrors={referringProviderSectionHasErrors}
									isCollapsed={this.state.collapse.referringProvider}
									name="referringProvider"
									referringProviderFieldsInfo={fieldGroupsInfo.referringProvider}
									referringProvidersList={this.props.referringProviders.referringProviders}
									orgReferringProvidersList={this.props.referringProviders.orgReferringProviders}
									sectionConfig={this.props.viewConfig.sections.referringProvider}
									shouldShowSectionValidation={this.state.shouldShowSectionValidation.referringProvider}
									allowInternalReferringProviders={allowInternalReferringProviders}
									allowExternalReferringProviders={allowExternalReferringProviders}
									allowOrgReferringProviders={allowOrgReferringProviders}
									isServerTypeAheadLoading={this.state.isServerTypeAheadLoading}
									setOrgProviderIdFromTypeahead={(selected) => this.setReferringProviderIdFromTypeahead(selected, true)}
									setReferringProviderIdFromTypeahead={(selected) => this.setReferringProviderIdFromTypeahead(selected, false)}
									handleOrgProviderServerTypeAheadSearch={(q) => this.handleReferringProviderServerTypeAheadSearch(q, true)}
									handleReferringProviderServerTypeAheadSearch={(q) => this.handleReferringProviderServerTypeAheadSearch(q, false)}
									handleOrgReferringProviderChange={this.handleOrgReferringProviderChange}
								/>
							)}
							{this.props.viewConfig.sections.customFieldsSection.isEnabled && preBookCustomFieldsComps && (
								<CustomFieldsSection
									enableSectionValidationIcons={this.props.enableSectionValidationIcons}
									handleInputBlur={this.handleInputBlur}
									handleInputChange={this.handleInputChange}
									handleInputFocus={this.handleInputFocus}
									handleToggleCollapse={this.handleToggleCollapse}
									hasErrors={customSectionHasErrors}
									isCollapsed={this.state.collapse.customFieldsPreBook}
									name="customFieldsPreBook"
									sectionConfig={this.props.viewConfig.sections.customFieldsSection}
									shouldShowSectionValidation={this.state.shouldShowSectionValidation.customFieldsPreBook}
								>
									{preBookCustomFieldsComps}
								</CustomFieldsSection>
							)}
							{this.props.viewConfig.sections.isWaitlistEnabled && (
								<EarlierAppointmentRequestSection
									name="earlierAppointmentRequestSection"
									value={this.state.isEarlierAppointmentRequested}
									isMobile={this.state.isMobile}
									requestEarlierAppointmentFieldConfig={this.props.viewConfig.sections.requestEarlierAppointmentFieldConfig}
									onChange={this.handleRequestEarlierAppointmentChange}
								/>
							)}
							{enableConditionsOfAccess && (
								<ConditionsOfAccessSection
									name="conditionsOfAccess"
									onChange={this.handleConditionsOfAccessChange}
									onLabelClick={this.toggleConditionsOfAccessModal}
									value={this.state.isConditionsOfAccessAccepted}
									showWarning={this.state.showConditionsOfAccessWarning}
									isMobile={this.state.isMobile}
								/>
							)}
							<Button.ButtonPrimary disabled={disableButtons || this.state.isReservationExpired || (enableConditionsOfAccess && !this.state.isConditionsOfAccessAccepted)} id={'confirm-appointment-btn'} type={'submit'}>
								Confirm
							</Button.ButtonPrimary>
							<DisclaimerHtml
								className="disclaimer patient-booking-disclaimer"
								isVisible={!!this.props.viewConfig.sections.patientBookingDisclaimer}
								text={this.props.viewConfig.sections.patientBookingDisclaimer}
							/>
							{this.state.isMobile && this.state.isReservation &&
								<div style={{ marginBottom: "0.5em" }} id={'mobileViewReservationCountDown'}>
									{!this.state.isReservationExpired ? (
										<div className="reservationCountDown">
											Reservation will expire in <span><CountDownTimer countDownExpirationTime={moment.utc(this.props.reservation.reservationExpirationTimeString).local().format()} countDownExpired={this.handleReservationExpired} /></span>
										</div>
									) : (
										<div className="reservationCountDown">
											Reservation expired, Please <span className="link" onClick={(e) => this.handleChangeAppointmentClick(e)}>pick a slot again.</span>
										</div>
									)}
								</div>
							}
						</form>
					</main>
				</div>
				{this.state.showConditionsOfAccessModal && (
					<ConditionsOfAccessModal
						toggleModal={this.toggleConditionsOfAccessModal}
						text={this.props.viewConfig.sections.patientBookingDisclaimer}
					/>
				)}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeCareOrderDetails: state.careOrder.activeCareOrderDetails,
		auth: state.auth,
		config: state.config,
		availabilitySearch: state.availabilitySearch,
		customFields: state.customFields,
		decisionSupport: state.decisionSupport,
		decisionSupportSessionId: state.session.decisionSupportSessionId,
		enableSectionValidationIcons:
			state.config.fieldConfig.appointmentHeaders.patientDetails.isVisible &&
			state.config.fieldConfig.appointmentHeaders.contactDetails.isVisible &&
			state.config.fieldConfig.appointmentHeaders.symptomDetails.isVisible,
		externalReferralOrderId: state.appointment.externalReferralOrderId,
		fieldConfig: state.config.fieldConfig,
		languageList: state.config.availabilitySearchSupportData.languageList,
		patient: state.patient,
		patientFieldConfig: state.config.patient,
		postBookCustomFieldsModalVisible: false,
		processWorkflow: {
			// TODO: Implement process workflow logic based on configuration (and also support customization)
			showProcessPanel: false,
			steps: [
				{ number: 1, name: 'DecisionSupport', label: 'Find Appointment' },
				{ number: 2, name: 'AvailabilitySearch', label: 'Select Appointment' },
				{ number: 3, name: 'BookAppointment', label: 'Confirm Appointment' },
			],
			currentStepName: 'BookAppointment',
		},
		providerFieldConfig: state.config.provider,
		referralSiteId: state.auth.referralSiteId,
		referralSystemId: state.auth.referralSystemId,
		referringProviderConfig: state.config.scheduling.referringProvider,
		referringProviders: state.referringProviders,
		reservation: state.appointment.reservation,
		reservationDetails: state.appointment.reservationDetails,
		schedulingConfig: state.config.scheduling,
		session: state.session,
		stateList: state.config.availabilitySearchSupportData.stateList.map((x) => {
			return { id: x.idPgmState, code: x.stateCode, name: x.state };
		}),
		suppressParentOrGuardianRequirement: state.config.system.suppressParentOrGuardianRequirement,
		symptomDetails: state.appointment.symptomDetails,
		token: state.auth.token,
		unmanagedReferringServiceName: state.session.bookingContext.unmanagedReferringServiceName,
		viewConfig: {
			sections: {
				appointmentSummary: {
					allowChangeAppointment: state.config.scheduling.enableChangeTimeSlot,
				},
				isWaitlistEnabled: state.config.scheduling.enableWaitlist,
				requestEarlierAppointmentFieldConfig: state.config.scheduling.requestEarlierAppointmentFieldConfig,
				patientBookingDisclaimer: state.config.scheduling.patientBookingDisclaimer,
				patientDetails: {
					headerText: state.config.fieldConfig.appointmentHeaders.patientDetails.fieldLabel,
					isCollapsable: state.config.branding.enableCollapsableSections,
					showHeader: state.config.fieldConfig.appointmentHeaders.patientDetails.isVisible,
					patientDetailsConfigInstructions: state.config.scheduling.patientDetailsConfigInstructions,
				},
				contactDetails: {
					headerText: state.config.fieldConfig.appointmentHeaders.contactDetails.fieldLabel,
					isCollapsable: state.config.branding.enableCollapsableSections,
					showHeader: state.config.fieldConfig.appointmentHeaders.contactDetails.isVisible,
					allowEmailConfirmationOtherEmail: state.config.scheduling.notification.allowEmailConfirmationOtherEmail,
					allowEmailReminderOtherEmail: state.config.scheduling.notification.allowEmailReminderOtherEmail,
					allowTextReminderOtherPhone: state.config.scheduling.notification.allowTextReminderOtherPhone,
					allowVoiceReminderOtherPhone: state.config.scheduling.notification.allowVoiceReminderOtherPhone,
					otherOptionText: 'Other', // NOTE: this is the text shown to the use in the selection drop-down NOT the label above the other input box
					patientEmailOptionText: 'Use Patient Email',
					otherEmailOptionText: 'Enter Other Email',
					emailInstructionsText: state.config.scheduling.notification.emailInstructionsText,
					phoneInstructionsText: state.config.scheduling.notification.phoneInstructionsText,
					contactDetailsConfigInstructions: state.config.scheduling.contactDetailsConfigInstructions,
				},
				customFieldsSection: {
					isEnabled: state.config.scheduling.enableCustomFields,
					headerText: state.config.fieldConfig.appointmentHeaders.customFields.fieldLabel,
					isCollapsable: state.config.branding.enableCollapsableSections,
					showHeader: state.config.fieldConfig.appointmentHeaders.customFields.isVisible,
					customFieldsConfigInstructions: state.config.scheduling.customFieldsConfigInstructions,
				},
				referringProvider: {
					headerText: state.config.fieldConfig.appointmentHeaders.referringProvider.fieldLabel,
					isCollapsable: state.config.branding.enableCollapsableSections,
					showHeader: state.config.fieldConfig.appointmentHeaders.referringProvider.isVisible,
					isEnabled: state.config.scheduling.referringProvider.useReferringProviders,
					allowInternalReferringProviders: state.config.scheduling.referringProvider.allowInternalReferringProviders,
					allowExternalReferringProviders: state.config.scheduling.referringProvider.allowExternalReferringProviders,
					allowOrgReferringProviders: state.config.scheduling.referringProvider.allowOrgReferringProviders,
					referringProviderConfigInstructions:
						state.config.scheduling.referringProvider.referringProviderConfigInstructions,
					enableServerTypeAhead: state.config.scheduling.referringProvider.enableServerTypeAhead,
					serverTypeAheadMinimumCharacters: state.config.scheduling.referringProvider.serverTypeAheadMinimumCharacters,
				},
				symptomDetails: {
					headerText: state.config.fieldConfig.appointmentHeaders.symptomDetails.fieldLabel,
					isCollapsable: state.config.branding.enableCollapsableSections,
					showHeader: state.config.fieldConfig.appointmentHeaders.symptomDetails.isVisible,

					symptomDetailsConfigInstructions: state.config.scheduling.symptomDetailsConfigInstructions,
				},
				conditionsOfAccessDetails: {
					isEnabled: state.config.scheduling.enableConditionsOfAccess,
				},
			},
			text: {
				textReminderConsentLabel: state.config.fieldConfig.notifications.textReminderOptIn.fieldLabel,
				voiceReminderConsentLabel: state.config.fieldConfig.notifications.voiceReminderOptIn.fieldLabel,
				emailConfirmationConsentLabel: state.config.fieldConfig.notifications.emailConfirmationOptIn.fieldLabel,
				emailReminderConsentLabel: state.config.fieldConfig.notifications.emailReminderOptIn.fieldLabel,
			},
		},
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(
			{
				getReservationDetails,
				getCustomFields,
				getReferringProviders,
				clearAllErrors,
				bookAppointmentWithNewPatient,
				cancelReservation,
				getOrgReferringProviders,
				searchReferringProviders,
				searchOrgProviders,
				searchAddress,
				getAddress,
			},
			dispatch,
		),
		dispatch,
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookAppointmentView));
