import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import './polyfills';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import buildFontAwesomeLibrary from './buildFontAwesomeLibrary';
import history from './lib/history';
import { store } from './store';
import config from './config';
import * as Sentry from "@sentry/react";

buildFontAwesomeLibrary();

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: config.sentry.dsn,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
			// new Sentry.BrowserProfilingIntegration(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		profilesSampleRate: 1.0,
		ignoreErrors: ['gtag/js', 'ruxitagentjs'],
	});
}

const rootElement = document.getElementById('root') as HTMLElement;

if (!rootElement) {
	throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);

root.render(
	<Provider store={store}>
		<ReduxRouter history={history}>
			<App />
		</ReduxRouter>
	</Provider>,
);

