import React from 'react';
import { LoadingIndicator } from './loadingIndicator';
import { Modal } from '../modal/modal';
import '../../css/loadingIndicator.css';
import '../../css/modal.css';

export function LoadingIndicatorModal(props) {
    const { message } = props;
    return (
        <Modal
            containerId="loadingIndicatorModalContainer"
            modalId="loadingIndicatorModal"
        >
            <LoadingIndicator
                message={message}
            />
        </Modal>
    );
};

export default LoadingIndicatorModal;