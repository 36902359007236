import React from 'react';
import { NavigateFunction, useNavigate, useLocation, useParams, Location } from 'react-router-dom';

interface RouterProps<Params = Record<string, string | undefined>> {
  navigate: NavigateFunction;
  location: Location;
  params: Params;
}

function withRouter<P extends RouterProps>(Component: React.ComponentType<P>) {
  return function WrappedComponent(props: Omit<P, keyof RouterProps>) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        {...(props as P)}
        navigate={navigate}
        location={location}
        params={params}
      />
    );
  };
}

export default withRouter;
