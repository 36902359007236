import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectAppliedFilters,
    selectAppointmentTypeModalityFilterOptions,
    selectContextAvailabilitySearchCriteria,
    selectDaysOfWeekFilterOptions, selectGenderFilterOptions,
    selectInitialFilters,
    selectIsSearchingAnything,
    selectLanguageFilterOptions,
    selectRadiusFilterOptions,
    selectScanDayCount,
    selectServiceFilterOptions,
    selectShowScanNextRangeButton,
    selectSiteFilterOptions,
    selectSortOrderFilterOptions,
}
    from "../availabilitySlice/availabilitySelectors";
import { ASC } from '../../../constants/availabilitySearchFields';
import moment from "moment";
import { DATE_FORMATS } from "../../../constants/dateFormats";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT, applyFiltersThunk, searchNextScanRangeWithReSortThunk } from "../availabilitySlice";

import { Modal } from '../../../components/modal/modal';
import * as FieldControl from '../../../components/input';
import * as Button from '../../../components/buttons';
import * as Icon from '../../../components/icons';
import { getFieldsConfig } from '../../../lib/multiProviderAvailabilityHelper';
import { getFieldGroupsInfoFromFieldsConfig } from '../../../lib/fieldConfigUtils';

import { buildFieldRefFromId, filterArray } from '../../../lib/fieldUtils';
import { INPUT_TYPES } from '../../../constants/inputTypes';
import { validateSection } from '../../../lib/validationUtils';

const mapNewFiltersToLegacy = (newFilters) => {

    const timeOfDay = [];
    if (newFilters.minStartTime === "12:00:00" && !newFilters.maxStartTime)
        timeOfDay.push("PM");

    if (newFilters.maxStartTime === "12:00:00" && !newFilters.minStartTime)
        timeOfDay.push("AM");

    return {
        [ASC.appointmentType]: newFilters.appointmentTypeIds?.length > 0 ? newFilters.appointmentTypeIds[0] : "",
        [ASC.appointmentModalityFilter]: newFilters.appointmentTypeModalityIds?.length > 0 ? newFilters.appointmentTypeModalityIds[0] : "",
        [ASC.appointmentStartDate]: moment.utc(newFilters.viewBaseDate).startOf('day').format(DATE_FORMATS.standard),
        [ASC.providerGender]: newFilters.gender,
        [ASC.insuranceCarrier]: newFilters.insuranceCarrierId,
        [ASC.insuranceState]: newFilters.insuranceStateId,
        [ASC.languagePreference]: newFilters.language,
        [ASC.payorType]: newFilters.payorType,
        [ASC.searchRadius]: newFilters.radius,
        [ASC.serviceName]: newFilters.services?.length > 0 ? newFilters.services[0] : "",
        [ASC.serviceCategory]: newFilters.serviceCategoryId,
        [ASC.siteFilter]: newFilters.sites?.length > 0 ? newFilters.sites[0] : "",
        [ASC.sortOrder]: newFilters.sortOrder,
        [ASC.specialty]: newFilters.specialtyId,
        [ASC.timeOfDay]: timeOfDay,
        [ASC.daysOfWeek]: newFilters.daysOfWeek || [],
        [ASC.zipCode]: newFilters.zipCode,
    };
}

const mapLegacyFiltersToNew = (legacyFilters) => {
    return {
        appointmentTypeIds: legacyFilters[ASC.appointmentType] ? [legacyFilters[ASC.appointmentType]] : [],
        appointmentTypeModalityIds: legacyFilters[ASC.appointmentModalityFilter] ? [legacyFilters[ASC.appointmentModalityFilter]] : [],
        viewBaseDate: dayjs(legacyFilters[ASC.appointmentStartDate]).format(DEFAULT_DATE_FORMAT),
        gender: legacyFilters[ASC.providerGender],
        insuranceCarrierId: legacyFilters[ASC.insuranceCarrier],
        insuranceStateId: legacyFilters[ASC.insuranceState],
        language: legacyFilters[ASC.languagePreference],
        payorType: legacyFilters[ASC.payorType],
        radius: legacyFilters[ASC.searchRadius],
        serviceCategoryId: legacyFilters[ASC.serviceCategory],
        sortOrder: Number(legacyFilters[ASC.sortOrder]),
        specialtyId: legacyFilters[ASC.specialty],
        //TODO
        minStartTime: legacyFilters[ASC.timeOfDay].includes("PM") && !legacyFilters[ASC.timeOfDay].includes("AM")
            ? "12:00:00"
            : null,
        maxStartTime: legacyFilters[ASC.timeOfDay].includes("AM") && !legacyFilters[ASC.timeOfDay].includes("PM")
            ? "12:00:00"
            : null,
        daysOfWeek: legacyFilters[ASC.daysOfWeek],
        zipCode: legacyFilters[ASC.zipCode],
        sites: legacyFilters[ASC.siteFilter] ? [legacyFilters[ASC.siteFilter]] : [],
        serviceName: legacyFilters[ASC.serviceName],
        services: legacyFilters[ASC.serviceName] ? [legacyFilters[ASC.serviceName]] : []
    }
}

const AvailabilityResultsFilters = ({ isMobile }) => {
    const initialFilters = useSelector(selectInitialFilters);
    const appliedFilters = useSelector(selectAppliedFilters);
    const searchFields = useSelector(state => state.config.availabilitySearch.searchFields);
    const contextAvailabilitySearchCriteria = useSelector(selectContextAvailabilitySearchCriteria);
    const shouldAutoSearch = useSelector(state => state.careOrder?.activeCareOrderDetails?.appointments || state.config.decisionSupport.useDecisionSupport);

    const isFocusedLookup = {};

    const fieldsConfig = useMemo(() => getFieldsConfig(searchFields), [searchFields]);

    const initialInputCriteria = useMemo(() => {
        const translatedFilters = mapNewFiltersToLegacy(initialFilters);
        return translatedFilters;
    }, [initialFilters]);

    const [formFieldValues, setFormFieldValues] = useState(initialInputCriteria);

    const [formErrors, setFormErrors] = useState([]);

    const [isModalShowing, setIsModalShowing] = useState(!shouldAutoSearch);

    const handleCloseModal = (e) => {
        let target = e.target;
        if (isModalShowing && target.id === 'availabilityFilterModalContainer') {
            setIsModalShowing(false);
        }
    };

    useEffect(
        () => {
            setFormFieldValues(mapNewFiltersToLegacy(appliedFilters));
        },
        [appliedFilters]
    )

    const immediateFieldsInfo = getFieldGroupsInfoFromFieldsConfig(
        formErrors,
        formFieldValues,
        fieldsConfig,
        { availabilitySearchCriteria: contextAvailabilitySearchCriteria },
        isFocusedLookup,
        true,
    ).navBar;

    let modalFieldsInfo = getFieldGroupsInfoFromFieldsConfig(
        formErrors,
        formFieldValues,
        fieldsConfig,
        { availabilitySearchCriteria: contextAvailabilitySearchCriteria },
        isFocusedLookup,
        true,
    );
    modalFieldsInfo = { ...modalFieldsInfo.navBar, ...modalFieldsInfo.modal };

    const startDate = formFieldValues[ASC.appointmentStartDate];
    const zipVal = formFieldValues[ASC.zipCode];

    /* eslint-disable */
    const validateFormFieldsTrigger = useEffect(() => {
        const newErrors = validateSection(
            'modal',
            formErrors,
            { modal: { ...formFieldValues } },
            { availabilitySearchCriteria: contextAvailabilitySearchCriteria },
            fieldsConfig,
        );
        setFormErrors(newErrors);
    }, [contextAvailabilitySearchCriteria, startDate, zipVal]);
    /* eslint-enable */

    return (
        <div className="availability-results-filters">
            <AvailabilityResultsFilterNavBar
                isMobile={isMobile}
                navBarFieldsInfo={immediateFieldsInfo}
                setIsModalShowing={setIsModalShowing}
            />
            {isModalShowing &&
                <AvailabilityResultsFilterModal
                    isMobile={isMobile}
                    modalFieldsInfo={modalFieldsInfo}
                    formFieldValues={formFieldValues}
                    setFormFieldValues={setFormFieldValues}
                    formErrors={formErrors}
                    fieldsConfig={fieldsConfig}
                    appliedFilters={appliedFilters}
                    initialInputCriteria={initialInputCriteria}
                    handleCloseModal={handleCloseModal}
                    setIsModalShowing={setIsModalShowing}
                />
            }
        </div>
    )
}

const AvailabilityResultsFilterNavBar = ({
    isMobile,
    navBarFieldsInfo,
    setIsModalShowing
}) => {
    const dispatch = useDispatch();

    const appliedFilters = useSelector(selectAppliedFilters);
    const showScanNextRangeButton = useSelector(selectShowScanNextRangeButton);
    const numberOfDaysToSearch = useSelector(selectScanDayCount);
    const isSearching = useSelector(selectIsSearchingAnything);
    const sortOrderFilterOptions = useSelector(selectSortOrderFilterOptions);
    const serviceFilterOptions = useSelector(selectServiceFilterOptions);
    const siteFilterOptions = useSelector(selectSiteFilterOptions);
    const appointmentTypeModalityFilterOptions = useSelector(selectAppointmentTypeModalityFilterOptions);

    return (<div className="availability-results-filter-navbar">
        <div className="dropdown-filters">
            {!isMobile && (
                <>
                    <FieldControl.DropdownSelect
                        {...navBarFieldsInfo[ASC.sortOrder]}
                        onChange={(e) => dispatch(applyFiltersThunk(
                            { ...appliedFilters, sortOrder: Number(e?.target?.value) }
                        ))}
                        optionList={sortOrderFilterOptions.map(({ id, label }) => ({ id: id, name: label }))}
                        size="md"
                    />
                    <FieldControl.DropdownSelect
                        {...navBarFieldsInfo[ASC.serviceName]}
                        allowEmptyValue={true}
                        onChange={(e) => dispatch(applyFiltersThunk(
                            { ...appliedFilters, services: e?.target?.value ? [e.target.value] : [], serviceName: e?.target?.value ? e.target.value : '' }
                        ))}
                        optionList={[{ id: '', name: 'All' }, ...serviceFilterOptions.map(({ id, label }) => ({ id, name: label }))]}
                        size="md"
                    />
                    <FieldControl.DropdownSelect
                        {...navBarFieldsInfo[ASC.siteFilter]}
                        allowEmptyValue={true}
                        onChange={(e) => dispatch(applyFiltersThunk(
                            { ...appliedFilters, sites: e?.target?.value ? [e.target.value] : [], siteFilter: e?.target?.value ? e.target.value : '' }
                        ))}
                        optionList={[{ id: '', name: 'All' }, ...siteFilterOptions.map(({ id, label }) => ({ id, name: label }))]}
                        size="md"
                    />
                    <FieldControl.DropdownSelect
                        {...navBarFieldsInfo[ASC.appointmentModalityFilter]}
                        allowEmptyValue={true}
                        onChange={(e) => dispatch(applyFiltersThunk(
                            { ...appliedFilters, appointmentTypeModalityIds: e?.target?.value ? [e.target.value] : [], appointmentModalityFilter: e?.target?.value ? e.target.value : '' }
                        ))}
                        optionList={[{ id: '', name: 'All' }, ...appointmentTypeModalityFilterOptions.map(({ id, label }) => ({ id, name: label }))]}
                        size="md"
                    />
                </>
            )}
        </div>

        <div className="availability-filter-button">
            <div className="scan-next-range-button">
                {showScanNextRangeButton && (
                    <Button.ButtonSecondary
                        color="secondary"
                        onClick={() => dispatch(searchNextScanRangeWithReSortThunk())}
                        disabled={isSearching}>
                        <span className="filter-button">Search Next {numberOfDaysToSearch} Days <Icon.IconChevronRight size={'sm'} /></span>

                    </Button.ButtonSecondary>
                )}
            </div>
            <div>
                <Button.ButtonPrimary
                    color="primary"
                    onClick={() =>
                        setIsModalShowing(x => !x)
                    }>
                    <span className="filter-button"><Icon.IconFilter className="filter-icon" size={'sm'} /> &nbsp;Filter</span>
                </Button.ButtonPrimary>
            </div>
        </div>
    </div >);
}

const AvailabilityResultsFilterModal = ({
    isMobile,
    modalFieldsInfo,
    formFieldValues,
    setFormFieldValues,
    formErrors,
    fieldsConfig,
    appliedFilters,
    initialInputCriteria,
    handleCloseModal,
    setIsModalShowing
}) => {

    const dispatch = useDispatch();

    const isReserving = useSelector(state => state.appointment.isLoading.reserveAppointment);
    const isSearchingAnything = useSelector(selectIsSearchingAnything);
    const languageFilterOptions = useSelector(selectLanguageFilterOptions);
    const sortOrderFilterOptions = useSelector(selectSortOrderFilterOptions);
    const radiusFilterOptions = useSelector(selectRadiusFilterOptions);
    const genderFilterOptions = useSelector(selectGenderFilterOptions);
    const daysOfWeekFilterOptions = useSelector(selectDaysOfWeekFilterOptions);
    const serviceFilterOptions = useSelector(selectServiceFilterOptions);
    const siteFilterOptions = useSelector(selectSiteFilterOptions);
    const appointmentTypeModalityFilterOptions = useSelector(selectAppointmentTypeModalityFilterOptions);
    const contextAvailabilitySearchCriteria = useSelector(selectContextAvailabilitySearchCriteria);

    const handleInputChange = (e) => {
        e.preventDefault();

        const fieldRef = buildFieldRefFromId(e.target.name);
        let fieldConfig = modalFieldsInfo[fieldRef.fieldName];
        let newValue = null;

        if (fieldConfig.inputType === INPUT_TYPES.CHECKBOX) {
            newValue = !formFieldValues[fieldRef.fieldName];
        } else if (fieldConfig.inputType === INPUT_TYPES.DROPDOWN) {
            newValue = parseInt(e.target.value, 10) || e.target.value;
        } else if (fieldConfig.inputType === INPUT_TYPES.BUTTON_OPTIONS) {
            newValue = filterArray(
                e.target.value,
                fieldConfig.isMultiSelect ? [...formFieldValues[fieldRef.fieldName]] : formFieldValues[fieldRef.fieldName],
                fieldConfig.isMultiSelect,
                fieldConfig.isInteger,
            );
        } else {
            newValue = e.target.value;
        }

        setFormFieldValues((x) => {
            return { ...x, [fieldRef.fieldName]: newValue };
        });
    };

    const submitForm = () => {
        if (validateSection('modal', formErrors, { modal: { ...formFieldValues } }, { availabilitySearchCriteria: contextAvailabilitySearchCriteria }, fieldsConfig).length < 1) {
            const intendedFilters = {
                ...appliedFilters,
                ...mapLegacyFiltersToNew(formFieldValues)
            };
            dispatch(applyFiltersThunk(intendedFilters));
        }
    };

    const resetForm = () => {
        const appliedMapped = mapNewFiltersToLegacy(appliedFilters);

        let updatedFormFieldValues = isMobile
            ? {
                ...initialInputCriteria,
            }
            : {
                ...initialInputCriteria,
                [ASC.appointmentModalityFilter]: appliedMapped[ASC.appointmentModalityFilter],
                [ASC.sortOrder]: appliedMapped[ASC.sortOrder],
                [ASC.serviceName]: appliedMapped[ASC.serviceName],
                [ASC.siteFilter]: appliedMapped[ASC.siteFilter],
            };

        setFormFieldValues(updatedFormFieldValues);
    };

    return (
        <Modal
            containerId="availabilityFilterModalContainer"
            modalId="availabilityFilterModal"
            onClickOffModalHandler={handleCloseModal}
        >
            <AvailabilityResultsFilterModalHeader
                toggleFilter={() => setIsModalShowing(x => !x)}
            />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitForm();
                    setIsModalShowing(false);
                }}
                className="availability-filter-modal-form"
            >
                <FieldControl.DateInput {...modalFieldsInfo[ASC.appointmentStartDate]} onChange={handleInputChange} />
                {isMobile && (
                    <>
                        <FieldControl.DropdownSelect
                            {...modalFieldsInfo[ASC.sortOrder]}
                            onChange={handleInputChange}
                            optionList={sortOrderFilterOptions.map(({ id, label }) => ({ id: id, name: label }))}
                            size="md"
                        />
                        <FieldControl.DropdownSelect
                            {...modalFieldsInfo[ASC.serviceName]}
                            allowEmptyValue={true}
                            onChange={handleInputChange}
                            optionList={[{ id: '', name: 'All' }, ...serviceFilterOptions.map(({ id, label }) => ({ id, name: label }))]}
                            size="md"
                        />
                        <FieldControl.DropdownSelect
                            {...modalFieldsInfo[ASC.siteFilter]}
                            allowEmptyValue={true}
                            onChange={handleInputChange}
                            optionList={[{ id: '', name: 'All' }, ...siteFilterOptions.map(({ id, label }) => ({ id, name: label }))]}
                            size="md"
                        />
                        <FieldControl.DropdownSelect
                            {...modalFieldsInfo[ASC.appointmentModalityFilter]}
                            allowEmptyValue={true}
                            onChange={handleInputChange}
                            optionList={[{ id: '', name: 'All' }, ...appointmentTypeModalityFilterOptions.map(({ id, label }) => ({ id, name: label }))]}
                            size="md"
                        />
                    </>
                )}
                <FieldControl.ButtonOptionsRow
                    {...modalFieldsInfo[ASC.timeOfDay]}
                    className={'time-of-day'}
                    onChange={handleInputChange}
                    optionList={[{ id: 'AM', name: 'AM' }, { id: 'PM', name: 'PM' }]}
                />
                <FieldControl.ButtonOptionsRow
                    {...modalFieldsInfo[ASC.daysOfWeek]}
                    className={'days'}
                    onChange={handleInputChange}
                    optionList={daysOfWeekFilterOptions.map(({ id, label, abbr }) => ({ id: String(id), name: abbr }))}
                />
                <FieldControl.ButtonOptionsRow
                    {...modalFieldsInfo[ASC.providerGender]}
                    className={'gender'}
                    onChange={handleInputChange}
                    optionList={genderFilterOptions.map(({ id, label }) => ({ id, name: label }))}
                />
                <FieldControl.DropdownSelect
                    {...modalFieldsInfo[ASC.languagePreference]}
                    allowEmptyValue={true}
                    onChange={handleInputChange}
                    optionList={[{ id: '', name: 'All' }, ...languageFilterOptions.map(({ id, label }) => ({ id, name: label }))]}
                />
                <FieldControl.DropdownSelect
                    {...modalFieldsInfo[ASC.searchRadius]}
                    className={'search-radius'}
                    onChange={handleInputChange}
                    optionList={radiusFilterOptions.map(({ id, label }) => ({ id, name: label })).filter((x) => x.id !== 0 && x.id !== '0')}
                />
                <FieldControl.ZipInput {...modalFieldsInfo[ASC.zipCode]} onChange={handleInputChange} />
                <div className="submit-buttons-container">
                    <Button.ButtonPrimary
                        className="apply-filters"
                        type="submit"
                        disabled={isSearchingAnything || isReserving}
                    >
                        Apply
                    </Button.ButtonPrimary>
                    <Button.ButtonSecondary
                        className="reset-filters"
                        type="button"
                        onClick={resetForm}
                        disabled={isSearchingAnything || isReserving}
                    >
                        Reset
                    </Button.ButtonSecondary>
                </div>
            </form>
        </Modal>);
}

export function AvailabilityResultsFilterModalHeader({ toggleFilter }) {
    return (
        <div className="filter-modal-header">
            <h1>More Filters</h1>
            <Icon.IconClose className="close-icon" onClick={toggleFilter} />
        </div>
    );
}

export default AvailabilityResultsFilters;