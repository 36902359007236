import { legacy_createStore as createStore, compose, applyMiddleware } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS } from 'immutable';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';

import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import persistState from 'redux-sessionstorage';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import { publishError } from './actions/errorActions';

import history from './lib/history';

import * as Sentry from "@sentry/react";

const keysToPersist = [
	'activeSolution',
	'appointment',
	'auth',
	'availability',
	'careOrder',
	'config',
	'customFields',
	'decisionSupport',
	'guidedResponse',
	'modal',
	'patient',
	'provider',
	'session',
	'surveyOrder',
	'workflow',
];

const client = axios.create({
	responseType: 'json',
	headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

const axiosMiddlewareConfig = {
	interceptors: {
		request: [
			function ({ getState }, req) {
				const authState = getState().auth;
				if (authState.isAuthenticated) {
					req.headers.Authorization = `Bearer ${authState.token}`;
				}
				if (authState.productInstanceId > 0) {
					req.headers['ProductInstanceId'] = authState.productInstanceId;
				}
				if (authState.referralSiteId > 0) {
					req.headers['ReferralSiteId'] = authState.referralSiteId;
				}
				req.baseURL = `api/internal`;
				return req;
			},
		],
		response: [
			{
				error: ({ dispatch, getSourceAction }, error) => {

					if (error.response && error.response.status === 503) {
						return retryRequest(error);
					}

					if (typeof error.response.data === 'object' && error.response.data !== null && error.response.data.detail) {
						dispatch(publishError(getSourceAction(error.config).type, error.response.data));
					} else if (
						typeof error.response.data === 'object' &&
						error.response.data !== null &&
						error.response.data.userMessage
					) {
						error.response.data.detail = error.response.data.userMessage;
						dispatch(publishError(getSourceAction(error.config).type, error.response.data));
					} else if (error.response && error.response.status === 401) {
						// TODO: Refresh instance?
					} else if (
						error.response &&
						error.response.status === 400 &&
						typeof error.response.data === 'object' &&
						error.response.data !== null &&
						!error.response.data.detail
					) {
						error.response.data.detail = 'Some validation error occurred.';
						dispatch(publishError(getSourceAction(error.config).type, error.response.data));
					} else {
						dispatch(publishError(getSourceAction(error.config).type, error.response.data));
					}
					throw error;
				},
			},
		],
	},
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	// Optionally pass options listed below
});

const MAX_RETRIES = 3;

const retryRequest = (error) => {
	const config = error.config;

	if (!config || config.__retryCount >= MAX_RETRIES) {
		return Promise.reject(error);
	}

	config.__retryCount = config.__retryCount || 0;

	config.__retryCount += 1;

	const backoff = new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, 1000); // Retry after 1 second (can be adjusted or made exponential)
	});

	return backoff.then(() => {
		return client(config);
	});
};

function configureStoreProd(initialState, history) {
	const routerMiddleware = createRouterMiddleware(history);
	const middlewares = [
		thunk,
		routerMiddleware,
		axiosMiddleware(client, axiosMiddlewareConfig),
	  ];
	const enhancer = compose(
	  applyMiddleware(...middlewares),
	  sentryReduxEnhancer,
	  persistState(keysToPersist),
	);
	const store = createStore(
		rootReducer(history),
		fromJS(initialState),
		enhancer
	);

	return store;
}

function configureStoreDev(initialState, history) {
	const routerMiddleware = createRouterMiddleware(history);
	const middlewares = [
		thunk,
		routerMiddleware,

		// Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
		reduxImmutableStateInvariant(),
		axiosMiddleware(client, axiosMiddlewareConfig),
	];

	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
	const enhancer = composeEnhancers(
	  applyMiddleware(...middlewares),
	  persistState(keysToPersist),
	);
	const store = createStore(
		rootReducer(history),
		initialState,
		enhancer
	)

	return store;
}

export default function configureStore(initialState = {}, history) {
	const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;
	const store = configureStore(initialState, history);
	return store;
}

/**
 * @typedef {import('./store').AppDispatch} AppDispatch - Import your AppDispatch type from the store
 */

/**
 * A typed version of the useDispatch hook.
 * @returns {AppDispatch} - The typed dispatch function
 */
export function useAppDispatch() {
	return useDispatch();
}

/**
 * @typedef {import('./store').RootState} RootState - Import your RootState type from the store
 */

/**
 * A typed version of the useSelector hook.
 * @type {(selector: (state: RootState) => any) => any}
 */
export function useAppSelector(selector) {
	return useSelector(selector);
}

const initialState = {};
export const store = configureStore(initialState, history);