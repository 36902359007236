import React from 'react';

export function LoadingIndicator(props) {
    const { message } = props;
    return (
        <div className="nighthawk-loading-indicator">
            <div className="eh-spinner eh-spinner-wave eh-margin-bottom-md eh-margin-top-sm">
                <div className="eh-rect1" />
                <div className="eh-rect2" />
                <div className="eh-rect3" />
                <div className="eh-rect4" />
                <div className="eh-rect5" />
            </div>
            <span className="eh-h2 eh-margin-bottom-lg">
                {message && <span>{message}</span>}
                {!message && <span>Loading Please Wait...</span>}
            </span>
        </div>
    );
};

export default LoadingIndicator;
