import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { closeProductAlertBanner, getProductAlertConfig } from '../../actions/productAlertConfigActions';
import { productIds } from '../../constants/productAlertConfigValues';
import { Grid, GridItem } from '../guidedResponse/layouts';
import { useAppDispatch } from 'store';
import ReadonlyHtml from './readonlyHtml';

library.add(faInfoCircle, faTimes);

const INTERVAL_IN_MS = 300000;

export default function ProductAlertBanner({ config }) {
	const dispatch = useAppDispatch();
	const { isActive, wasAlertBannerClosed } = useSelector((s) => s.ops.productAlertConfigsReducer);
	let intervalId = useRef();

	useEffect(() => {
		if (isActive) {
			intervalId.current = window.setInterval(() => {
				dispatch(getProductAlertConfig(productIds.ADMIN2));
			}, INTERVAL_IN_MS);
		} else {
			if (intervalId.current) {
				window.clearInterval(intervalId.current);
			}
		}
	}, [isActive, wasAlertBannerClosed, dispatch]);

	useEffect(() => {
		return () => {
			if (intervalId.current) {
				window.clearInterval(intervalId.current);
			}
		};
	}, []);

	const handleCloseAlertBannerClick = () => {
		dispatch(closeProductAlertBanner());
	};

	return(
		<Grid numberOfColumns={16}>
			<GridItem span={1}>
				<FontAwesomeIcon icon={faInfoCircle} size="lg" />
			</GridItem>
			<GridItem span={14}>
				<ReadonlyHtml
					label={config?.alertTitle ? config.alertTitle : 'Alert Title'}
					value={config?.alertMessage ? config.alertMessage : ''}
				/>
			</GridItem>
			<GridItem span={1} justifySelf='center' >
				<Button className='alertCloseButton' >
					<FontAwesomeIcon icon={faTimes} size="lg" onClick={handleCloseAlertBannerClick} />
				</Button>
			</GridItem>
		</Grid>
	);
}
