/**
 * @format
 */

import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { connect, useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { login, logoutUser } from '../../actions/authActions';
import {
	initializeBookingProcess,
	redirectToBookingProcess,
	initializeSurveyOrderProcess,
	setBookingContext,
	setGRDebugContext,
} from '../../actions/workflowActions';
import { setValidEntryPointToTrue } from '../../actions/sessionActions';
import { LoadingIndicatorModal } from '../../components//loadingIndicator/loadingIndicatorModal';
import * as routes from '../../routes';
import { useAppDispatch } from 'store';

export const ClientInstanceLogin = (props) => {
	let [isLoaded, isSetLoaded] = useState(false);
	let [loginComplete, setLoginComplete] = useState(false);
	let [failed, setFailed] = useState(false);
	let [queryString, setQueryString] = useState('');

	const dispatch = useAppDispatch();
	const location = useLocation();
	const params = useParams();

	// useSelector or mapStateToProps?
	let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	let bookingInitialized = useSelector((state) => state.session.bookingInitialized);
	let referralSystemId = useSelector((state) => state.auth.referralSystemId);

	let {
		activeCareOrder,
		auth,
		availabilitySearchConfig,
		bookingContext,
		correlationKey,
		decisionSupportOutput,
		isValidEntryPoint,
		patientAuthContext,
		routePrefix,
		surveyOrderContext,
		useDecisionSupport,
		activePatient,
	} = props;

	let locationSearch = location.search;
	let locationState = location.state;
	let enableRootUrl = props.enableRootUrl;
	let forceQueryStringLowerCase = props.forceQueryStringLowerCase;

	//login
	useEffect(() => {
		setLoginComplete(false);
		isSetLoaded(false);
		const queryString = locationState && locationState.from ? locationState.from.search : locationSearch;
		setQueryString(queryString);
		const rawParams = qs.parse(queryString, { ignoreQueryPrefix: true });
		const routePrefix = params.routePrefix;

		dispatch(logoutUser()).then((x) => {
			dispatch(login(rawParams.instanceName, routePrefix)).then(() => {
				setLoginComplete(true);
			});
		});
	}, [dispatch, locationState, locationSearch, params.routePrefix]);

	//process query string and set booking context
	useEffect(() => {
		if (loginComplete) {
			let rawParams = qs.parse(queryString, { ignoreQueryPrefix: true });
			const optionalParams = {};
			if (forceQueryStringLowerCase) {
				Object.keys(rawParams).forEach((key) => {
					optionalParams[key.toLowerCase()] = rawParams[key].toLowerCase();
				});
			} else {
				Object.keys(rawParams).forEach((key) => {
					optionalParams[key.toLowerCase()] = rawParams[key];
				});
			}
			dispatch(setGRDebugContext({debug: optionalParams.debug === 'on'}))
			dispatch(
				setBookingContext({
					npi: optionalParams.npi,
					siteId: optionalParams.siteid,
					specialtyId: optionalParams.specialtyid,
					specialtyName: optionalParams.specialtyname,
				}),
			);
		}
	}, [dispatch, forceQueryStringLowerCase, queryString, loginComplete]);

	useEffect(() => {
		if (!isLoaded && loginComplete) {
			if (!isAuthenticated) {
				setFailed(true);
			} else {
				isSetLoaded(true);
			}
		}
	}, [dispatch, isLoaded, isAuthenticated, loginComplete, referralSystemId]);

	/* eslint-disable */
	useEffect(() => {
		let isFromDecisionSupportWorkflow = locationState?.from?.pathname === '/decisionSupport/workflow';
		if (isAuthenticated && isLoaded && !isValidEntryPoint && loginComplete) {
			dispatch(setValidEntryPointToTrue());
			if (surveyOrderContext.referenceId) {
				dispatch(initializeSurveyOrderProcess(patientAuthContext, surveyOrderContext));
			} else if ((!locationState || isFromDecisionSupportWorkflow) && enableRootUrl) {
				dispatch(
					initializeBookingProcess(
						useDecisionSupport,
						correlationKey,
						activeCareOrder,
						decisionSupportOutput,
						availabilitySearchConfig,
						activePatient,
					),
				);
			}
		}
	}, [
		dispatch,
		isAuthenticated,
		isLoaded,
		locationState,
		enableRootUrl,
		auth,
		bookingContext,
		useDecisionSupport,
		patientAuthContext,
		availabilitySearchConfig,
		surveyOrderContext,
		isValidEntryPoint,
		loginComplete,
		correlationKey,
	]);
	/* eslint-enable */

	useEffect(() => {
		if (bookingInitialized && isAuthenticated && loginComplete) {
			dispatch(redirectToBookingProcess(auth, patientAuthContext, useDecisionSupport, bookingContext, routePrefix));
		}
	}, [
		dispatch,
		bookingInitialized,
		auth,
		patientAuthContext,
		bookingContext,
		useDecisionSupport,
		routePrefix,
		loginComplete,
		isAuthenticated,
	]);

	const { invalidUrlMessage } = props;

	if (isLoaded) {
		if (locationState) {
			const { from } = locationState;
			return <Navigate to={from} />;
		} else if (!locationState && !enableRootUrl) {
			return (
				<Navigate
					to={{
						pathname: '/invalidUrl',
						state: {
							invalidUrlMessage: invalidUrlMessage,
						},
					}}
				/>
			);
		}
	} else if (failed) {
		dispatch(routes.failedLogin());
	}
	return (
		<div className="view-content">
			<LoadingIndicatorModal />
		</div>
	);
};

function mapStateToProps(state, ownProps) {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.patient,
		auth: state.auth,
		availabilitySearchConfig: state.config.availabilitySearch,
		bookingContext: state.session.bookingContext,
		config: state.config,
		correlationKey: state.session.correlationKey,
		decisionSupportOutput: state.decisionSupport,
		enableRootUrl: state.auth.enableRootUrl,
		forceQueryStringLowerCase: state.auth.forceQueryStringLowerCase,
		invalidUrlMessage: state.auth.invalidUrlMessage,
		isAuthenticated: state.auth.isAuthenticated,
		isValidEntryPoint: state.session.validEntryPoint,
		patientAuthContext: state.patient.patientAuthContext,
		permissions: state.auth.permissions,
		routePrefix: state.config.instance.routePrefix,
		session: state.session,
		surveyOrderContext: state.surveyOrder,
		useDecisionSupport: state.config.decisionSupport.useDecisionSupport,
	};
}

export default connect(mapStateToProps)(ClientInstanceLogin);
