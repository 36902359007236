import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

interface Auth {
	isAuthenticated: boolean;
	email: string;
}

let reactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | null = null;

interface TelemetryService {
	reactPlugin: ReactPlugin;
	appInsights: ApplicationInsights | null;
	initialize: (instrumentationKey: string, browserHistory: History) => void;
	updateData: (auth: Auth) => void;
}

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = (): TelemetryService => {
	/**
	 * Initialize the Application Insights instance
	 * @param {string} instrumentationKey - Application Insights Instrumentation Key
	 * @param {History} browserHistory - The client's browser history (e.g., from `history` library)
	 * @return {void}
	 */
	const initialize = (instrumentationKey: string, browserHistory: History): void => {
		if (!browserHistory) {
			throw new Error('Could not initialize Telemetry Service.');
		}
		if (!instrumentationKey) {
			throw new Error('Instrumentation key not provided.');
		}

		appInsights = new ApplicationInsights({
			config: {
				autoTrackPageVisitTime: true,
				instrumentationKey: instrumentationKey,
				disableFetchTracking: false,
				enableAutoRouteTracking: true,
				enableCorsCorrelation: true,
				enableRequestHeaderTracking: true,
				extensions: [reactPlugin],
				extensionConfig: {
					[reactPlugin.identifier]: {
						history: browserHistory,
					},
				},
				loggingLevelTelemetry: 2,
				maxBatchInterval: 0,
			},
		});

		appInsights.loadAppInsights();
	};

	/**
	 * Update telemetry data with authenticated user information
	 * @param {Auth} auth - Authentication details of the user
	 * @return {void}
	 */
	const updateData = (auth: { isAuthenticated: any; email: any }) => {
		if (auth && auth.isAuthenticated) {
			if (appInsights) {
				const telemetryInitializer = (envelope: ITelemetryItem): void => {
					if (envelope.data) {
						envelope.data.username = auth.email;
					}
				};
				appInsights.addTelemetryInitializer(telemetryInitializer);
			} else {
				console.warn('App Insights not initialized. Telemetry not updated.');
			}
		}
	};

	return { reactPlugin, appInsights, initialize, updateData };
};

export const ai = createTelemetryService();
export const getAppInsights = (): ApplicationInsights | null => appInsights;
