import * as types from '../actions/actionTypes';
import * as appointmentFieldsConst from '../constants/appointmentFields';
import * as availabilitySearchFieldsConst from '../constants/availabilitySearchFields';
import * as patientFieldsConst from '../constants/patientFields';
import * as providerFieldsConst from '../constants/providerFields';
import * as patientAuthFieldsConst from '../constants/patientAuthFields';
import * as clientBrandingFieldConst from '../constants/clientBrandingFields';
import { DEFAULT_LABELS } from '../constants/defaultLabels';
import * as patientBehavior from '../lib/fieldBehavior';
import * as fieldBehavior from '../lib/fieldBehavior';

const initialState = {
	analytics: {
		analyticsToken: '',
		analyticsTrackerName: '',
		googleTagManagerId: '',
	},
	availabilitySearch: {
		chunkSize: 0,
		defaultPageSize: 0,
		defaultSearchRadius: 0,
		maxNumberOfCalendars: 0,
		searchDays: 0,
		availabilitySearchWindow: 0,
		availabilitySearchWindowUsed: 0,
		availabilitySortOrder: {
			availabilitySortOrderId: null,
			displayName: '',
			name: '',
		},
		appointmentDateBehavior: null,
		enableAppointmentTypeTooltips: true,
		suppressSaturday: false,
		suppressSunday: false,
		payorTypeBehavior: null,
		insuranceStateBehavior: null,
		insuranceCarrierBehavior: null,
		serviceCategoryBehavior: null,
		skipToFirstAvailability: true, //TODO add to config
		specialtiesBehavior: null,
		zipCodeBehavior: null,
		searchRadiusBehavior: null,
		serviceNameBehavior: null,
		providerGenderBehavior: null,
		languagePreferenceBehavior: null,
		allowReturnToDecisionSupport: false,
		searchPreferredProvidersFirst: false,
		useNewAvailabilitySearchViewByDefault: false
	},
	availabilitySearchSupportData: {},
	clientBranding: {
		appointmentDetailsLabel: {},
		appointmentSuccessLabel: {},
		additionalInformationLabel: {},
		scheduleFollowUpLabel: {},
		providerDetailsLabel: {},
	},
	decisionSupport: {
		useDecisionSupport: false,
		useGuidedResponse: false,
		flowId: null,
		apWorkspaceId: null,
		apAlgorithmId: null,
		apServerUrl: null,
		apUseJwt: false,
		enableFlowTesting: false,
		enableURLDebug: false,
	},
	fieldConfig: {
		appointmentFields: {
			reasonForVisit: {},
			managedReferringProvider: {},
			unmanagedReferringProvider: {},
		},
		appointmentHeaders: {
			patientDetails: {},
			contactDetails: {},
			referringProvider: {},
			symptomDetails: {},
			customFields: {},
		},
		notifications: {
			thirdPartyEmail: {},
			notificationLanguage: {},
			emailConfirmationOptIn: {},
			emailConfirmationSelection: {},
			emailConfirmationOtherEmail: {},
			emailReminderOptIn: {},
			emailReminderSelection: {},
			emailReminderOtherEmail: {},
			textReminderOptIn: {},
			textReminderSelection: {},
			textReminderOtherPhone: {},
			voiceReminderOptIn: {},
			voiceReminderSelection: {},
			voiceReminderOtherPhone: {},
		},
	},
	idProductInstance: null,
	patient: {
		allowUnknownGender: false,
		unknownGenderOptionText: 'Unspecified',
		idPgmStateDefault: 0,
		isViewEnabled: true,
		isEditEnabled: true,
		isAddEnabled: true,
		isPatientHistoryEnabled: true,
		patientHistory: {
			isViewAppointmentDetailsEnabled: true,
			isCancelAppointmentEnabled: true,
			isIncludeCancelledAppointmentsEnabled: true,
			includeCancelledAppointmentsDefault: false,
		},
		guardian: {
			fieldLabel: 'Guardian Name',
			isActive: true,
			maxLength: 255,
			adultAge: 18,
		},
	},
	patientAuth: {
		enablePatientAuth: false,
		validateBirthYear: {},
		validateDateOfBirth: {},
		validateZipCode: {},
		validationInstructions: '',
	},
	provider: {
		fax: {},
		phone: {},
	},
	scheduling: {
		enableCancelAppointment: true,
		languageId: 0,
		maxAttachments: 0,
		notification: {
			emailConfirmationEnabled: false,
			emailCancellationEnabled: false,
			emailReminderEnabled: false,
			minEmailReminderLeadTime: 0,
			minTextReminderLeadTime: 0,
			minVoiceReminderLeadTime: 0,
			showPatientConfirmationEmailOptIn: false,
			showPatientCancellationEmailOptIn: false,
			textReminderEnabled: false,
			voiceReminderEnabled: false,
		},
		referringProvider: {
			useReferringProviders: false,
			allowInternalReferringProviders: false,
			allowExternalReferringProviders: false,
			allowOrgReferringProviders: false,
			enableServerTypeAhead: false,
			serverTypeAheadMinimumCharacters: 0,
		},
		patientInformationDisclaimer: '',
		showReasonForVisitApptDetails: false,
		reasonForVisitCharacterLimit: 8000,
		enableConditionsOfAccess: true,
		enableCustomFields: false,
		showReasonForCancellation: false,
		enableCustomCancelReasons: false,
		suppressParentOrGuardianRequirement: false,
		showAvailabilitySearchDistance: true,
		showProviderDetailsDistance: true,
		enableWaitlist: false,
	},
	surveyOrder: {
		enableSurveyOrders: false,
		invalidSurveyOrderMessage: '',
	},
	system: {},
	instance: {
		isInstanceUnavailable: false,
		instanceUnavailableText: '',
		errorMessageOverride: '',
		routePrefix: '',
	},
	systemCancelReasons: [],
};

function createFieldConfigObjectFromFieldGroupList(fieldGroups) {
	let configObject = {};
	for (const fieldGroup of fieldGroups) {
		const groupName = parseFieldGroupPart(fieldGroup);
		const group = createFieldGroupObjectFromFieldGroup(fieldGroup);
		configObject[groupName] = group;
	}
	return configObject;
}

function createFieldGroupObjectFromFieldGroup(fieldGroup) {
	let group = {};
	for (const fieldConfig of fieldGroup.fields) {
		const fieldName = parseFieldNamePart(fieldConfig);
		let field = fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(fieldConfig);
		group[fieldName] = field;
	}
	return group;
}

function parseFieldGroupPart(fieldGroup) {
	let parts = fieldGroup.groupToken.split('.');
	let lastPart = parts.length > 0 ? parts[parts.length - 1] : 'CannotParseGroupToken';
	return forceCamelCase(lastPart);
}

function parseFieldNamePart(fieldConfig) {
	let parts = fieldConfig.fieldToken.split('.');
	let lastPart = parts.length > 0 ? parts[parts.length - 1] : 'CannotParseFieldToken';
	return forceCamelCase(lastPart);
}

function forceCamelCase(str) {
	if (str.length === 1) {
		str = str.charAt(0).toLowerCase();
	} else if (str.length > 1) {
		str = str.charAt(0).toLowerCase() + str.substring(1);
	}
	return str;
}

function resolveSystemCancelReasons(systemCancelReasons) {
	//let defaultOptionArray = systemCancelReasons.length > 1 ? [{ ...defaultOption }] : []; // this seems wrong
	let defaultOptionArray = [];

	let result = defaultOptionArray.concat(
		systemCancelReasons.map((cancelReason) => {
			return {
				id: cancelReason.id,
				name: cancelReason.cancelReason,
			};
		}),
	);
	return result;
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.AUTHENTICATE_USER_SUCCESS:
			var fieldGroups = action.payload.data.config.fieldGroups;

			var patientFields = fieldGroups.find((o) => o.groupToken === patientFieldsConst.PATIENT_FIELD_GROUP).fields;

			var providerFieldGroup = fieldGroups.find((o) => o.groupToken === providerFieldsConst.PROVIDER_FIELD_GROUP);
			var providerFields = providerFieldGroup ? providerFieldGroup.fields : [];

			var availabilitySearchFieldGroup = fieldGroups.find(
				(o) => o.groupToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_FIELD_GROUP,
			);
			var availabilitySearchFields = availabilitySearchFieldGroup ? availabilitySearchFieldGroup.fields : [];

			var patientAuthFieldGroup = fieldGroups.find(
				(o) => o.groupToken === patientAuthFieldsConst.PATIENT_AUTH_FIELD_GROUP,
			);
			var patientAuthFields = patientAuthFieldGroup ? patientAuthFieldGroup.fields : [];

			//var schedulingNotificationFieldGroups = fieldGroups.find(o => o.groupToken === notificationFieldsConst.NOTIFICATION_FIELD_GROUP);
			//var schedulingNotificationFields = schedulingNotificationFieldGroups ? schedulingNotificationFieldGroups.fields : [];

			var labelFieldGroups = fieldGroups.find((o) => o.groupToken === clientBrandingFieldConst.LABEL_FIELD_GROUP);
			var labelFields = labelFieldGroups ? labelFieldGroups.fields : [];

			var appointmentFieldGroup = fieldGroups.find(
				(o) => o.groupToken === appointmentFieldsConst.APPOINTMENT_FIELD_GROUP,
			);
			var appointmentFields = appointmentFieldGroup ? appointmentFieldGroup.fields : [];

			//var appointmentHeadersFieldGroup = fieldGroups.find(o => o.groupToken === blah.blah);
			//var appointmentHeaders = appointmentHeadersFieldGroup ? appointmentHeadersFieldGroup.fields : [];

			var fieldConfig = createFieldConfigObjectFromFieldGroupList(fieldGroups);
			return {
				...state,
				analytics: {
					...state.analytics,
					analyticsToken: action.payload.data.config.analytics.analyticsToken
						? action.payload.data.config.analytics.analyticsToken
						: '',
					analyticsTrackerName: action.payload.data.config.analytics.analyticsTrackerName
						? action.payload.data.config.analytics.analyticsTrackerName
						: '',
					googleTagManagerId: action.payload.data.config.analytics.googleTagManagerId
						? action.payload.data.config.analytics.googleTagManagerId
						: '',
				},
				availabilitySearch: {
					...state.availabilitySearch,
					...action.payload.data.config.availabilitySearch,
					searchFields: {
						appointmentStartDate: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_APPOINTMENT_START_DATE,
							),
						),
						insuranceState: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_INSURANCE_STATE,
							),
						),
						insuranceCarrier: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_INSURANCE_CARRIER,
							),
						),
						serviceCategory: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SERVICE_CATEGORY,
							),
						),
						specialty: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SPECIALTY,
							),
						),
						appointmentModalityFilter: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_APPOINTMENT_MODALITY_FILTER,
							),
						),
						appointmentType: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_APPOINTMENT_TYPE,
							),
						),
						zipCode: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_ZIP_CODE,
							),
						),
						searchRadius: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SEARCH_RADIUS,
							),
						),
						serviceName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SERVICE_NAME,
							),
						),
						providerGender: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_PROVIDER_GENDER,
							),
						),
						language: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_LANGUAGE,
							),
						),
						days: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_DAYS_OF_WEEK,
							),
						),
						timeOfDay: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_TIME_OF_DAY,
							),
						),
						payorType: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_PAYOR_TYPE,
							),
						),
						sortOrder: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SORT_ORDER,
							),
						),
						siteName: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SITE_NAME,
							),
						),
						siteFilter: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_SITE_FILTER,
							),
						),
						providerFilter: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							availabilitySearchFields.find(
								(o) => o.fieldToken === availabilitySearchFieldsConst.AVAILABILITY_SEARCH_PROVIDER_FILTER,
							),
						),
					},
				},
				availabilitySearchSupportData: {
					...state.availabilitySearchSupportData,
					...action.payload.data.config.availabilitySearchSupportData,
				},
				branding: {
					...state.branding,
					...action.payload.data.config.branding,
				},
				clientBranding: {
					...state.clientBranding,
					appointmentDetailsLabel: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						labelFields.find((o) => o.fieldToken === clientBrandingFieldConst.LABEL_FIELD_REVIEWAPPOINTMENTDETAILS),
					),
					appointmentSuccessLabel: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						labelFields.find((o) => o.fieldToken === clientBrandingFieldConst.LABEL_FIELD_APPOINTMENTSUCCESSLABEL),
					),
					additionalInformationLabel: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						labelFields.find((o) => o.fieldToken === clientBrandingFieldConst.LABEL_FIELD_ADDITIONALINFORMATIONLABEL),
					),
					scheduleFollowUpLabel: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						labelFields.find((o) => o.fieldToken === clientBrandingFieldConst.LABEL_FIELD_SCHEDULEFOLLOWUPLABEL),
					),
					providerDetailsLabel: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						labelFields.find((o) => o.fieldToken === clientBrandingFieldConst.LABEL_FIELD_PROVIDERDETAILSLABEL),
					),
					availabilitySearchHeader: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						labelFields.find((o) => o.fieldToken === clientBrandingFieldConst.LABEL_FIELD_AVAILABILITYSEARCHHEADER),
					),
				},
				decisionSupport: {
					...state.decisionSupport,
					...action.payload.data.config.decisionSupport,
				},
				fieldConfig: {
					...state.fieldConfig,
					...fieldConfig, // NOTE: if the server returns ANY field in a section, it FULLY replaces the section ...
				},
				idProductInstance: action.payload.data.config.id,
				patient: {
					...state.patient,
					allowUnknownGender: action.payload.data.config.allowUnknownGender,
					unknownGenderOptionText:
						action.payload.data.config.unknownGenderOptionText &&
							action.payload.data.config.unknownGenderOptionText !== null
							? action.payload.data.config.unknownGenderOptionText
							: 'Unspecified',
					idPgmStateDefault: action.payload.data.config.idPgmStateDefault
						? action.payload.data.config.idPgmStateDefault
						: 0,
					isAddEnabled: action.payload.data.config.enablePatientAdd,
					isEditEnabled: action.payload.data.config.enablePatientEdit,
					gender: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_GENDER),
						DEFAULT_LABELS.PATIENT.GENDER,
					),
					memberId: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_MEMBERID),
						DEFAULT_LABELS.PATIENT.MEMBERID,
					),
					groupNumber: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_GROUPNUMBER),
						DEFAULT_LABELS.PATIENT.GROUPNUMBER,
					),
					firstName: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_FIRSTNAME),
						DEFAULT_LABELS.PATIENT.FIRSTNAME,
					),
					middleName: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_MIDDLENAME),
						DEFAULT_LABELS.PATIENT.MIDDLENAME,
					),
					lastName: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_LASTNAME),
						DEFAULT_LABELS.PATIENT.LASTNAME,
					),
					addressLine1: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_ADDRESSLINE1),
						DEFAULT_LABELS.PATIENT.ADDRESSLINE1,
					),
					addressLine2: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_ADDRESSLINE2),
						DEFAULT_LABELS.PATIENT.ADDRESSLINE2,
					),
					city: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_CITY),
						DEFAULT_LABELS.PATIENT.CITY,
					),
					state: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_STATE),
						DEFAULT_LABELS.PATIENT.STATE,
					),
					zipCode: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_ZIPCODE),
						DEFAULT_LABELS.PATIENT.ZIPCODE,
					),
					dateOfBirth: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_DATEOFBIRTH),
						DEFAULT_LABELS.PATIENT.DATEOFBIRTH,
					),
					email: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_EMAIL),
						DEFAULT_LABELS.PATIENT.EMAIL,
					),
					homePhone: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_HOMEPHONE),
						DEFAULT_LABELS.PATIENT.HOMEPHONE,
					),
					mobilePhone: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_MOBILEPHONE),
						DEFAULT_LABELS.PATIENT.MOBILEPHONE,
					),
					alternatePhone: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_ALTERNATEPHONE),
						DEFAULT_LABELS.PATIENT.ALTERNATEPHONE,
					),
					parentOrGuardianName: patientBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientFields.find((o) => o.fieldToken === patientFieldsConst.PATIENT_FIELD_PARENT_OR_GUARDIAN_NAME),
						DEFAULT_LABELS.PATIENT.PARENT_OR_GUARDIAN_NAME,
					),
				},
				patientAuth: {
					...state.patientAuth,
					enablePatientAuth: action.payload.data.config.patientAuth.enablePatientAuth
						? action.payload.data.config.patientAuth.enablePatientAuth
						: false,
					validateBirthYear: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientAuthFields.find((o) => o.fieldToken === patientAuthFieldsConst.PATIENT_AUTH_FIELD_BIRTH_YEAR),
					),
					validateDateOfBirth: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientAuthFields.find((o) => o.fieldToken === patientAuthFieldsConst.PATIENT_AUTH_FIELD_DATE_OF_BIRTH),
					),
					validateZipCode: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						patientAuthFields.find((o) => o.fieldToken === patientAuthFieldsConst.PATIENT_AUTH_FIELD_ZIPCODE),
					),
					validationInstructions: action.payload.data.config.patientAuth.validationInstructions
						? action.payload.data.config.patientAuth.validationInstructions
						: '',
				},
				provider: {
					fax: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						providerFields.find((o) => o.fieldToken === providerFieldsConst.PROVIDER_FIELD_FAX),
					),
					phone: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						providerFields.find((o) => o.fieldToken === providerFieldsConst.PROVIDER_FIELD_PHONE),
					),
				},
				scheduling: {
					...state.scheduling,
					enableCancelAppointment: action.payload.data.config.scheduling.enableCancelAppointment,
					languageId: action.payload.data.config.notification.language
						? action.payload.data.config.notification.idLanguage
						: 0,
					requestEarlierAppointmentFieldConfig: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
						appointmentFields.find(
							(o) => o.fieldToken === appointmentFieldsConst.APPOINTMENT_REQUEST_EARLIER_APPOINTMENT,
						),
					),
					maxAttachments: action.payload.data.system.appointmentMaxAttachments,
					reservationDurationInMinutes: action.payload.data.config.scheduling.reservationDurationInMinutes,
					showReasonForVisitApptDetails: action.payload.data.config.scheduling.showReasonForVisitApptDetails,
					patientInformationDisclaimer: action.payload.data.config.scheduling.patientInformationDisclaimer,
					notification: {
						...state.scheduling.notification,
						emailConfirmationEnabled: action.payload.data.config.notification.enablePatientConfirmationEmail,
						showPatientConfirmationEmailOptIn:
							action.payload.data.config.notification.showPatientConfirmationEmailOptIn,
						allowEmailConfirmationOtherEmail: action.payload.data.config.notification.allowEmailConfirmationOtherEmail,
						patientConfirmationEmailDisclaimer:
							action.payload.data.config.notification.patientConfirmationEmailDisclaimer,
						emailCancellationEnabled: action.payload.data.config.notification.enablePatientCancellationEmail,
						showPatientCancellationEmailOptIn:
							action.payload.data.config.notification.showPatientCancellationEmailOptIn,
						allowEmailCancellationOtherEmail: action.payload.data.config.notification.allowEmailCancellationOtherEmail,
						patientCancellationEmailDisclaimer:
							action.payload.data.config.notification.patientCancellationEmailDisclaimer,
						emailReminderEnabled: action.payload.data.config.notification.enablePatientEmailReminder,
						allowEmailReminderOtherEmail: action.payload.data.config.notification.allowEmailReminderOtherEmail,
						minEmailReminderLeadTime: action.payload.data.config.notification.minEmailReminderLeadTime,
						patientReminderEmailDisclaimer: action.payload.data.config.notification.patientReminderEmailDisclaimer,
						textReminderEnabled: action.payload.data.config.notification.enablePatientSmsReminder,
						allowTextReminderOtherPhone: action.payload.data.config.notification.allowTextReminderOtherPhone,
						minTextReminderLeadTime: action.payload.data.config.notification.minTextReminderLeadTime,
						patientReminderSmsDisclaimer: action.payload.data.config.notification.patientReminderSmsDisclaimer,
						voiceReminderEnabled: action.payload.data.config.notification.enablePatientVoiceReminder,
						allowVoiceReminderOtherPhone: action.payload.data.config.notification.allowVoiceReminderOtherPhone,
						minVoiceReminderLeadTime: action.payload.data.config.notification.minVoiceReminderLeadTime,
						patientReminderVoiceDisclaimer: action.payload.data.config.notification.patientReminderVoiceDisclaimer,
						emailInstructionsText: action.payload.data.config.notification.emailInstructionsText,
						phoneInstructionsText: action.payload.data.config.notification.phoneInstructionsText,
					},
					referringProvider: {
						...state.scheduling.referringProvider,
						useReferringProviders: action.payload.data.config.scheduling.useReferringProviders,
						allowExternalReferringProviders: action.payload.data.config.scheduling.allowExternalReferringProviders,
						allowInternalReferringProviders: action.payload.data.config.scheduling.allowInternalReferringProviders,
						allowOrgReferringProviders: action.payload.data.config.scheduling.allowOrganizationReferringProviders,
						internalReferringProviderFieldConfig: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							appointmentFields.find(
								(o) => o.fieldToken === appointmentFieldsConst.APPOINTMENT_MANAGED_REFERRING_PROVIDER,
							),
						),
						externalReferringProviderFieldConfig: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							appointmentFields.find(
								(o) => o.fieldToken === appointmentFieldsConst.APPOINTMENT_UNMANAGED_REFERRING_PROVIDER,
							),
						),
						orgReferringProviderFieldConfig: fieldBehavior.mapFieldConfigInfoToSimpleFieldConfig(
							appointmentFields.find((o) => o.fieldToken === appointmentFieldsConst.APPOINTMENT_ORG_REFERRING_PROVIDER),
						),
						enableServerTypeAhead: action.payload.data.config.scheduling.enableInternalReferringProviderServerTypeAhead,
						serverTypeAheadMinimumCharacters:
							action.payload.data.config.scheduling.internalReferringProviderServerTypeAheadMinimumCharacters,
						referringProviderConfigInstructions:
							action.payload.data.config.scheduling.referringProviderConfigInstructions,
					},
					reasonForVisitCharacterLimit: action.payload.data.config.scheduling.reasonForVisitCharacterLimit,
					enableChangeTimeSlot: action.payload.data.config.scheduling.enableChangeTimeSlot,
					enableGetDirections: action.payload.data.config.scheduling.enableGetDirections,
					enableIcsFileDownload: action.payload.data.config.scheduling.enableIcsFileDownload,
					enableBookAnotherAppointment: action.payload.data.config.scheduling.enableBookAnotherAppointment,
					emailInstructionsText: action.payload.data.config.scheduling.emailInstructionsText,
					phoneInstructionsText: action.payload.data.config.scheduling.phoneInstructionsText,
					patientBookingDisclaimer: action.payload.data.config.scheduling.patientBookingDisclaimer,
					patientDetailsConfigInstructions: action.payload.data.config.scheduling.patientDetailsConfigInstructions,
					contactDetailsConfigInstructions: action.payload.data.config.scheduling.contactDetailsConfigInstructions,
					symptomDetailsConfigInstructions: action.payload.data.config.scheduling.symptomDetailsConfigInstructions,
					customFieldsConfigInstructions: action.payload.data.config.scheduling.customFieldsConfigInstructions,
					enableConditionsOfAccess: action.payload.data.config.scheduling.enableConditionsOfAccess,
					enableCustomFields: action.payload.data.config.scheduling.enableCustomFields,
					showReasonForCancellation: action.payload.data.config.scheduling.showReasonForCancellation,
					enableCustomCancelReasons: action.payload.data.config.scheduling.enableCustomCancelReasons,
					enableRequestAppointment: action.payload.data.config.scheduling.enableRequestAppointment,
					showAvailabilitySearchDistance: action.payload.data.config.scheduling.showAvailabilitySearchDistance,
					showProviderDetailsDistance: action.payload.data.config.scheduling.showProviderDetailsDistance,
					useDecisionSupportWithCareOrders: action.payload.data.config.scheduling.useDecisionSupportWithCareOrders,
					showProgressBar: action.payload.data.config.scheduling.showProgressBar,
					showAddressSearch: action.payload.data.config.scheduling.showAddressSearch,
					enableWaitlist: action.payload.data.config.scheduling.enableWaitlist
				},
				surveyOrder: {
					...state.surveyOrder,
					enableSurveyOrders: action.payload.data.config.surveyOrder.enableSurveyOrders,
					invalidSurveyOrderMessage: action.payload.data.config.surveyOrder.invalidSurveyOrderMessage
						? action.payload.data.config.surveyOrder.invalidSurveyOrderMessage
						: '',
				},
				system: {
					...state.system,
					isMemberIdActive: action.payload.data.system.isMemberIdActive,
					isMemberIdRequired: action.payload.data.system.isMemberIdRequired,
					memberIdFieldLabel: action.payload.data.system.memberIdFieldLabel,
					memberIdMaxLength: action.payload.data.system.memberIdMaxLength,
					isGroupNumberActive: action.payload.data.system.isGroupNumberActive,
					isGroupNumberRequired: action.payload.data.system.isGroupNumberRequired,
					groupNumberMaxLength: action.payload.data.system.groupNumberMaxLength,
					suppressParentOrGuardianRequirement: action.payload.data.system.suppressParentOrGuardianRequirement,
				},
				instance: {
					...state.instance,
					isInstanceUnavailable: action.payload.data.config.isInstanceUnavailable,
					instanceUnavailableText: action.payload.data.config.instanceUnavailableText,
					errorMessageOverride: action.payload.data.config.errorMessageOverride,
					routePrefix: action.payload.data.config.routePrefix,
				},
				systemCancelReasons: action.payload.data.config.cancelReasons
					? resolveSystemCancelReasons(action.payload.data.config.cancelReasons)
					: [],
			};

		default:
			return state;
	}
}
