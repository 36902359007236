//3rd party
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//actions
import { getActiveCareOrderDetails } from '../../actions/careOrderActions';
import { setEntryPointUrl, redirectToBookingProcess, initializeBookingProcess } from '../../actions/workflowActions';
import { processDecisionSupportSubpoints } from '../../actions/decisionSupportActions';

//constants
import * as flowTypes from '../../constants/flowTypeEnum';

//lib

//comps
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';
import { DisclaimerHtml } from '../../components/misc/miscComponents';
import { fetchDecisionSupportSessionOutput } from '../../actions/sessionActions';
import { withRouter } from 'hoc';

const DEFAULT_INVALID_CARE_ORDER_MESSAGE =
	'The link you have used is no longer active. To book an appointment please use an active link.';

export class StartCareOrderVisitBookRedirector extends React.Component {
	constructor(props) {
		super(props);
		this.careOrderVisitIdentifier = this.props.params.careOrderVisitId;
		this.state = {
			showLoadingIndicator: true,
			isCareOrderLoaded: false,
			showAlert: false,
		};
		this._isMounted = false;
	}

	dismissAlert = () => {
		this.setState({ showAlert: false });
	};

	componentDidMount() {
		this._isMounted = true;
		if (this.careOrderVisitIdentifier) {
			this.props.setEntryPointUrl(flowTypes.CARE_ORDER_FLOW, this.careOrderVisitIdentifier);
			this.loadActiveCareOrderDetails();
		} else {
			this.setState({
				showAlert: true,
				showLoadingIndicator: false,
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.session.bookingInitialized && !nextProps.activeCareOrderDetails.isInvalid) {
			let useDecisionSupport =
				nextProps.config.decisionSupport.useDecisionSupport &&
				nextProps.config.scheduling.useDecisionSupportWithCareOrders &&
				!nextProps.activeCareOrderDetails.decisionSupportSessionId; // if we are loading decision support output as part of the care order, dont' use decision support

			let { auth, patientAuthContext, bookingContext } = nextProps;
			nextProps.redirectToBookingProcess(
				auth,
				patientAuthContext,
				useDecisionSupport,
				bookingContext,
				this.props.config.instance.routePrefix,
			);
		} else if (
			nextProps.activeCareOrderDetails.careOrderVisitIdentifier
			&& (
				!this.props.activeCareOrderDetails.careOrderVisitIdentifier ||
				this.props.activeCareOrderDetails.careOrderVisitIdentifier !==
				nextProps.activeCareOrderDetails.careOrderVisitIdentifier
			)
			&& !nextProps.activeCareOrderDetails.decisionSupportSessionId
		) {

			let { availabilitySearchConfig, correlationKey, activeCareOrder, decisionSupportOutput, activePatient } =
				nextProps;

			let useDecisionSupport =
				nextProps.config.decisionSupport.useDecisionSupport &&
				nextProps.config.scheduling.useDecisionSupportWithCareOrders &&
				!nextProps.activeCareOrderDetails.decisionSupportSessionId; // if we are loading decision support output as part of the care order, dont' use decision support

			nextProps.initializeBookingProcess(
				useDecisionSupport,
				correlationKey,
				activeCareOrder,
				decisionSupportOutput,
				availabilitySearchConfig,
				activePatient,
			);
		}
	}

	loadActiveCareOrderDetails = () => {
		this.props.dispatch(
			async (dispatch, getState) => {
				try {
					const response = await dispatch(getActiveCareOrderDetails(this.careOrderVisitIdentifier, this.props.auth.productInstanceId));
					if (response.error) {
						this.setState({
							showAlert: true,
							showLoadingIndicator: false,
						});
					} else {
						if (response.payload.data.decisionSupportSessionId) {
							await dispatch(fetchDecisionSupportSessionOutput(response.payload.data.decisionSupportSessionId));
							let state = getState()
							await dispatch(processDecisionSupportSubpoints(state.session.decisionSupportSubpoints));
							state = getState();
							dispatch(initializeBookingProcess(
								false,
								state.session.correlationKey,
								state.careOrder,
								state.decisionSupport,
								state.config.availabilitySearch,
								state.patient,
							))
							if (this._isMounted) {
								this.setState({ showLoadingIndicator: false, isCareOrderLoaded: true });
							}
						} else {
							if (this._isMounted) {
								this.setState({ showLoadingIndicator: false, isCareOrderLoaded: true });
							}
						}
					}
				} catch (err) {
					this.setState({
						showAlert: true,
						showLoadingIndicator: false,
					});
				}
			}
		)
	};

	render() {
		let { showLoadingIndicator, isCareOrderLoaded } = this.state;
		let invalidCareOrderMessage = this.props.activeCareOrderDetails.errorMessage || DEFAULT_INVALID_CARE_ORDER_MESSAGE;
		return (
			<>
				{isCareOrderLoaded && this.props.activeCareOrderDetails.isInvalid && (
					<div className="view-content">
						<main className={'main-content invalid-care-order'}>
							<DisclaimerHtml isVisible={true} text={invalidCareOrderMessage} />
						</main>
					</div>
				)}
				{showLoadingIndicator && (
					<div>
						<LoadingIndicatorModal />
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		activeCareOrder: state.careOrder,
		activeCareOrderDetails: state.careOrder.activeCareOrderDetails,
		activePatient: state.patient,
		availabilitySearchConfig: state.config.availabilitySearch,
		auth: state.auth,
		bookingContext: state.session.bookingContext,
		careOrderClosedMessage: state.auth.careOrderClosedMessage,
		config: state.config,
		correlationKey: state.session.correlationKey,
		decisionSupportOutput: state.decisionSupport,
		patientAuthContext: state.patient.patientAuthContext,
		session: state.session,
		token: state.auth.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(
			{
				setEntryPointUrl,
				redirectToBookingProcess,
				initializeBookingProcess,
				getActiveCareOrderDetails,
			},
			dispatch,
		),
		dispatch,
	}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartCareOrderVisitBookRedirector));
