import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store';
import { verifyToken } from 'actions/authActions';
import { LoadingIndicatorModal } from 'components/loadingIndicator';
import { setValidEntryPointToTrue } from '@/actions/sessionActions';
//import PermissionWarning from 'components/misc/permissionWarning';
//import { useHasPermission } from 'components/permissions/hooks';

interface ProtectedRouteProps {
	element: React.ReactNode;
	permission?: string | string[] | false;
}

export const ProtectedRoute: React.FC<React.PropsWithChildren<ProtectedRouteProps>> = ({ element: Element, permission }) => {
	const [loaded, setLoaded] = useState(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { routePrefix } = useParams();

	const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
	const enableRootUrl = useAppSelector((state) => state.auth.enableRootUrl);
	const validEntryPoint = useAppSelector((state) => state.session.validEntryPoint);
	const invalidUrlMessage = useAppSelector((state) => state.auth.invalidUrlMessage);
	const instanceRoutePrefix = useAppSelector((state) => state.config.instance.routePrefix);

	const isNewRoutePrefix =
		(routePrefix?.toLowerCase() || '') !== (instanceRoutePrefix?.toLowerCase() || '');

	//const hasPermission = useHasPermission(permission === false ? '' : permission ?? '') || permission === false;

	useEffect(() => {
		let unmounted = false;
		dispatch(verifyToken()).then(() => {
			if (!unmounted) setLoaded(true);

			if (!unmounted && validEntryPoint) {
				dispatch(setValidEntryPointToTrue());
			}
		});

		return () => {
			unmounted = true;
		};
	}, [dispatch]);

	useEffect(() => {
		if (loaded && !isAuthenticated) {
			navigate(routePrefix ? `/${routePrefix}` : '/', { state: { from: location } });
		} else if (isAuthenticated && isNewRoutePrefix) {
			navigate(routePrefix ? `/${routePrefix}` : '/', { state: { from: location } });
		} else if (isAuthenticated && enableRootUrl && !validEntryPoint) {
			navigate(routePrefix ? `/${routePrefix}` : '/');
		} else if (isAuthenticated && !enableRootUrl && !validEntryPoint) {
			navigate('/invalidUrl', { state: { invalidUrlMessage } });
		}
	}, [
		loaded,
		isAuthenticated,
		isNewRoutePrefix,
		enableRootUrl,
		validEntryPoint,
		invalidUrlMessage,
		routePrefix,
		location,
		navigate,
	]);

	if (!loaded) {
		return (
			<div className="view-content">
				<LoadingIndicatorModal />
			</div>
		);
	}

	// if (!hasPermission) {
	// 	return <PermissionWarning />;
	// }

	if (isAuthenticated && validEntryPoint) {
		return Element;
	}

	return null;
};

export default ProtectedRoute;
