import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './telemetryService';

interface TelemetryProviderProps {
	children: React.ReactNode;
	instrumentationKey: string;
	auth: { isAuthenticated: boolean; email: string };
}

const TelemetryProvider: React.FC<React.PropsWithChildren<TelemetryProviderProps>> = ({ children, instrumentationKey }) => {
	const location = useLocation();
	const initialized = useRef(false);

	useEffect(() => {
		if (!initialized.current && instrumentationKey) {
			ai.initialize(instrumentationKey, window.history);
			initialized.current = true;
		}
	}, [instrumentationKey]);

	useEffect(() => {
		if (ai.appInsights) {
			ai.appInsights.trackPageView({ uri: location.pathname });
		}
	}, [location.pathname]);

	return <>{children}</>;
};

export default withAITracking(ai.reactPlugin, TelemetryProvider);
