import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import * as routes from '../../routes';
import { processDecisionSupportSubpoints } from '../../actions/decisionSupportActions';
import { startAvailabilitySearch } from '../../actions/workflowActions';
import AppliedPathwaysComponent from '../../components/appliedPathways/appliedPathwaysComponent';
import GuidedResponseComponent from '../../components/guidedResponse/guidedResponseComponent';

export class DecisionSupportView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			chainGuidedResponseFlowFromAp: false,
			chainedFlowUniqueToken: null,
			subpointsFromAppliedPathways: null,
			enableDebuggerFromAp: false,
		};
	}

	handleCompleted = (subpoints, endedInGuidedResponse) => {
		this.props.processDecisionSupportSubpoints(subpoints, endedInGuidedResponse).then((data) => {
			this.props
				.startAvailabilitySearch(
					data.activeCareOrder,
					data.decisionSupportOutput,
					data.correlationKey,
					data.availabilitySearchConfig,
					data.patientDetails,
				)
				.then(() => {
					this.props.dispatch(routes.searchAvailability(this.props.config.instance.routePrefix));
				});
		});
	};

	onChainFlow = (subpoints) => {
		let chainedFlowUniqueToken = subpoints.find((subpoint) => subpoint.key === 'chainedflowuniquetoken').value;
		this.setState({
			chainGuidedResponseFlowFromAp: true,
			chainedFlowUniqueToken: chainedFlowUniqueToken,
			subpointsFromAppliedPathways: subpoints,
			enableDebuggerFromAp: this.props.decisionSupportConfig.enableFlowTesting,
		});
	};

	handleAppliedPathwaysFlowComplete = (results) => {
		this.handleCompleted(results, false);
	};

	handleGuidedResponseFlowComplete = (results) => {
		this.handleCompleted(results, true);
	};

	buildAppliedPathwaysInputData = (patient, bookingContext) => {
		return { ...bookingContext, patient: patient };
	};

	buildGuidedResponseInputData = (patient, activeCareOrderDetails, bookingContext, tokenLink) => {
		var fields = [];

		this.mapBookingContextToGuidedResponseInputFields(fields, bookingContext);
		this.mapPatientToGuidedResponseInputFields(fields, patient);
		this.mapCareOrderToGuidedResponseInputFields(fields, activeCareOrderDetails);

		if (this.state.subpointsFromAppliedPathways != null)
			this.mapAppliedPathwaysSubpointsToGuidedResponseInputs(fields, this.state.subpointsFromAppliedPathways);

		if (tokenLink.inputValueType && tokenLink.inputName && tokenLink.inputValue)
			this.mapTokenLinkToGuidedResponseInputs(fields, tokenLink);
		
		return { inputFields: fields };
	};

	mapBookingContextToGuidedResponseInputFields = (fields, bookingContext) => {
		if (bookingContext) {
			this.addOrOverrideFieldIfPresent(fields, 'Npi', 'String', bookingContext.npi);
			this.addOrOverrideFieldIfPresent(fields, 'SiteId', 'String', bookingContext.siteId);
			this.addOrOverrideFieldIfPresent(fields, 'SpecialtyId', 'String', bookingContext.specialtyId);
			this.addOrOverrideFieldIfPresent(fields, 'specialtyName', 'String', bookingContext.specialtyName);
		}
	};

	mapPatientToGuidedResponseInputFields = (fields, patient) => {
		if (patient) {
			this.addOrOverrideFieldIfPresent(fields, 'PatientReferenceId', 'String', patient.referenceId);
			this.addOrOverrideFieldIfPresent(fields, 'PatientMemberId', 'String', patient.memberId);
			this.addOrOverrideFieldIfPresent(fields, 'PatientGroupNumber', 'String', patient.groupNumber);
			this.addOrOverrideFieldIfPresent(fields, 'PatientFirstName', 'String', patient.firstName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientMiddleName', 'String', patient.middleName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientLastName', 'String', patient.lastName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientDateOfBirth', 'Date', this.getDateString(patient.dateOfBirth));
			this.addOrOverrideFieldIfPresent(fields, 'PatientGenderCode', 'String', patient.gender);
			this.addOrOverrideFieldIfPresent(fields, 'PatientGuardianName', 'String', patient.guardianName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientEmail', 'String', patient.email);
			this.addOrOverrideFieldIfPresent(fields, 'PatientHomePhone', 'String', patient.homePhone);
			this.addOrOverrideFieldIfPresent(fields, 'PatientMobilePhone', 'String', patient.mobilePhone);
			this.addOrOverrideFieldIfPresent(fields, 'PatientAlternatePhone', 'String', patient.alternatePhone);
			this.addOrOverrideFieldIfPresent(fields, 'PatientAddressLine1', 'String', patient.addressLine1);
			this.addOrOverrideFieldIfPresent(fields, 'PatientAddressLine2', 'String', patient.addressLine2);
			this.addOrOverrideFieldIfPresent(fields, 'PatientCityName', 'String', patient.cityName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientStateCode', 'String', patient.stateCode);
			this.addOrOverrideFieldIfPresent(fields, 'PatientZipCode', 'String', patient.zipCode);
			this.addOrOverrideFieldIfPresent(fields, 'PatientLatitude', 'String', patient.latitude);
			this.addOrOverrideFieldIfPresent(fields, 'PatientLongitude', 'String', patient.longitude);
			this.addOrOverrideFieldIfPresent(fields, 'PatientPoolPrefix', 'String', patient.patientPoolPrefix);
		}
	};

	mapCareOrderToGuidedResponseInputFields = (fields, activeCareOrderDetails) => {
		if (activeCareOrderDetails) {
			if (!fields.find((f) => f.fieldName === 'PatientZipCode')) {
				this.addOrOverrideFieldIfPresent(fields, 'PatientZipCode', 'String', activeCareOrderDetails.patientZip);
			}

			this.addOrOverrideFieldIfPresent(
				fields,
				'MaxVisitDate',
				'Date',
				this.getDateString(activeCareOrderDetails.maxVisitDate),
			);
			this.addOrOverrideFieldIfPresent(
				fields,
				'MinVisitDate',
				'Date',
				this.getDateString(activeCareOrderDetails.minVisitDate),
			);
			this.addOrOverrideFieldIfPresent(
				fields,
				'OrderExpirationDate',
				'Date',
				this.getDateString(activeCareOrderDetails.expirationDate),
			);

			if (activeCareOrderDetails.appointments && activeCareOrderDetails.appointments.length === 1) {
				let appointment = activeCareOrderDetails.appointments[0];

				this.addOrOverrideFieldIfPresent(fields, 'AppointmentTypeId', 'String', appointment.appointmentTypeId);
				this.addOrOverrideFieldIfPresent(fields, 'AppointmentTypeName', 'String', appointment.appointmentTypeName);

				//currently NPI isn't passed via careorders/appt BUT if it ever is then we have a hook for it.
				this.addOrOverrideFieldIfPresent(fields, 'NPI', 'String', appointment.npi);
				this.addOrOverrideFieldIfPresent(fields, 'ServiceTypeId', 'String', appointment.serviceTypeId);
				this.addOrOverrideFieldIfPresent(fields, 'ServiceId', 'String', appointment.serviceId);
				this.addOrOverrideFieldIfPresent(fields, 'SpecialtyId', 'String', appointment.specialtyId);
				this.addOrOverrideFieldIfPresent(fields, 'SiteId', 'String', appointment.siteId);
				this.addOrOverrideFieldIfPresent(
					fields,
					'ExternalReferralOrderNumber',
					'String',
					appointment.externalReferralOrderId,
				);

				if (appointment.careOrderCustomField !== null && appointment.careOrderCustomField.length) {
					appointment.careOrderCustomField.forEach((x) => {
						this.addOrOverrideFieldIfPresent(fields, `${x.fieldName}`, 'String', `${x.fieldValue}`);
					});
				}
			}
		}
	};

	mapTokenLinkToGuidedResponseInputs = (fields, tokenLink) => {
		switch (tokenLink.inputValueType.toLowerCase()) {
			case 'date':
				this.addOrOverrideFieldIfPresent(fields, tokenLink.inputName, tokenLink.inputValueType, this.getDateString(tokenLink.inputValue));
				break;
			case 'string':
			default:
				this.addOrOverrideFieldIfPresent(fields, tokenLink.inputName, tokenLink.inputValueType, tokenLink.inputValue);
				break;
		}
	};

	mapAppliedPathwaysSubpointsToGuidedResponseInputs = (fields, subpoints) => {
		if (subpoints === null || subpoints === undefined) return;

		subpoints.forEach((subpoint) => {
			//TODO: Because I don't know what subpoints they may or may not send, I can't interrogate
			//the subpoints to set the inputType correctly going into GuidedResponse.
			//As a result I'm going to send them in as strings.
			if (subpoint.key.toLowerCase() === "patientdateofbirth") {
				//since patientdateofbirth is a standard input, we want to pass this as its actual type since
				//we know it will be present from some AP flows.
				this.addOrOverrideFieldIfPresent(fields, subpoint.key, 'Date', this.getDateString(subpoint.value));
			} else {
				this.addOrOverrideFieldIfPresent(fields, subpoint.key, 'String', subpoint.value);
			}
		});
	};

	getDateString = (date) => {
		if (date !== null && date !== undefined) {
			const d = moment(date).format('YYYY-MM-DD');
			return d;
		}
	};

	//TODO: move addFieldIfPresent and createField to a GuidedResponseHelpers file?
	addOrOverrideFieldIfPresent = (fields, fieldName, valueType, currentValue) => {
		// Note: The "if present" refers the presence of a currentValue to assign, not a field in the list
		if (currentValue) {
			let field = fields.find((f) => f.fieldName.toLowerCase() === fieldName.toLowerCase());
			if (field === undefined) {
				field = this.createField(fieldName, valueType, currentValue);
				fields.push(field);
			} else {
				// Code assumes value types agree, else we have a bug
				field.currentValue = currentValue;
			}
		}
	};

	createField = (fieldName, valueType, currentValue) => {
		var field = {
			fieldName: fieldName,
			valueType: valueType,
			currentValue: currentValue,
		};
		return field;
	};

	goBackToAppliedPathways = () => {
		this.setState({
			chainGuidedResponseFlowFromAp: false,
		});
	};

	render() {
		if (!this.props.decisionSupportConfig.useDecisionSupport) {
			this.props.dispatch(routes.home(this.props.config.instance.routePrefix));
			return null;
		}
		let useGuidedResponseFlow =
			this.state.chainGuidedResponseFlowFromAp || this.props.decisionSupportConfig.useGuidedResponse || this.props.flowSessionResponse?.flowSessionId.length;

		let inputData = useGuidedResponseFlow
			? this.buildGuidedResponseInputData(
					this.props.patient,
					this.props.activeCareOrder.activeCareOrderDetails,
					this.props.bookingContext,
					this.props.tokenLink,
			  )
			: this.buildAppliedPathwaysInputData(this.props.patient, this.props.bookingContext);

		return (
			<>
				{useGuidedResponseFlow && (
					<GuidedResponseComponent
						contextData={inputData}
						correlationKey={this.props.correlationKey}
						goBackToAppliedPathways={this.goBackToAppliedPathways}
						decisionSupportConfig={this.props.decisionSupportConfig}
						chainGuidedResponseFlowFromAp={this.state.chainGuidedResponseFlowFromAp}
						chainedFlowUniqueToken={this.state.chainedFlowUniqueToken}
						chainedFlowDecisionSupportSessionId={this.props.appliedPathways.workbox.id}
						enableDebuggerFromAp={this.state.enableDebuggerFromAp}
						isLoading={this.props.isLoading}
						onCompleted={this.handleGuidedResponseFlowComplete}
						productInstanceId={this.props.auth.productInstanceId}
						patientReferenceId={this.props.patient?.referenceId}
						//careOrderVisitIdentifier={this.props.activeCareOrder.activeCareOrderDetails.careOrderVisitIdentifier}
					/>
				)}
				{!useGuidedResponseFlow && (
					<AppliedPathwaysComponent
						contextData={inputData}
						correlationKey={this.props.correlationKey}
						decisionSupportConfig={this.props.decisionSupportConfig}
						isLoading={this.props.isLoading}
						onCompleted={this.handleAppliedPathwaysFlowComplete}
						onChainFlow={this.onChainFlow}
					/>
				)}
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.patient,
		appliedPathways: state.appliedPathways,
		auth: state.auth,
		availabilitySearchConfig: state.config.availabilitySearch,
		bookingContext: state.session.bookingContext,
		config: state.config,
		correlationKey: state.session.correlationKey,
		decisionSupportConfig: state.config.decisionSupport,
		decisionSupportOutput: state.decisionSupport,
		flowSessionResponse: state.guidedResponse.flowSessionResponse,
		isLoading: state.ajaxCallsInProgress > 0,
		patient: state.patient.patient,
		patientConfig: state.config.patient,
		tokenLink: state.session.tokenLink,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(
			{
				processDecisionSupportSubpoints,
				startAvailabilitySearch,
			},
			dispatch,
		),
		dispatch,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DecisionSupportView);
