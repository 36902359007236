/**
 * @format
 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAppointmentById, getPatientIcsFile } from '../../actions/appointmentActions';
import { clearAllErrors } from '../../actions/errorActions';
import { clearDecisionSupport } from '../../actions/workflowActions';
import { setPatientDemographics } from '../../actions/patientActions';
import { scrollToPageTop } from '../../lib/misc';
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';
import AppointmentSummaryPanel from '../../components/appointment/appointmentSummaryPanel';
import AdditionalInformationDetails from '../../components/appointment/additionalInformationDetails';
import * as Icon from '../../components/icons';
import * as Button from '../../components/buttons';
import ActiveErrors from '../../components/misc/activeErrors';
import * as routes from '../../routes';
import { startWorkflow } from '../../features/schedulingWorkflow/schedulingWorkflowSlice';
import { withRouter } from 'hoc';

const MAX_MOBILE_WIDTH = 992;

export class AppointmentDetailsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			collapse: {
				appointmentSummary: true, // NOTE: this bit is slightly misleading, it isn't really "collapsed" like the others in desktop, only in mobile version
			},
			areAppointmentDetailsLoading: false,
			isCancellingAppointment: false,
			isGettingIcsFile: false,
			isMobile: false,
		};
	}

	appointmentDetails = (referenceId) => {
		this.setState({ areAppointmentDetailsLoading: true });
		this.props
			.getAppointmentById(referenceId)
			.then((response) => {
				if (response.error) {
					this.setState({ areAppointmentDetailsLoading: false });
					scrollToPageTop();
				}
				this.setState({
					areAppointmentDetailsLoading: false,
				});
			})
			.catch((err) => {
				this.setState({ areAppointmentDetailsLoading: false });
				scrollToPageTop();
			});
	};

	componentDidMount() {
		window.addEventListener('resize', this.resize.bind(this));
		this.resize();
		const params = this.props.params;
		scrollToPageTop();
		this.appointmentDetails(params.referenceId);
	}

	resize() {
		this.setState({ isMobile: window.innerWidth <= MAX_MOBILE_WIDTH });
	}

	getCalendar = () => {
		this.setState({ isGettingIcsFile: true });
		this.props.clearAllErrors();
		this.props
			.getPatientIcsFile(this.props.details.referenceId, this.props.token, this.props.config.idProductInstance)
			.then((response) => {
				if (response.error) {
					this.setState({ isGettingIcsFile: false });
					scrollToPageTop();
				} else {
					const blob = new Blob([response.payload.data.Entity], { type: 'text/calendar;' });
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = 'Appointment.ics';
					link.click();
					this.setState({ isGettingIcsFile: false });
				}
			})
			.catch((err) => {
				this.setState({ isGettingIcsFile: false });
				scrollToPageTop();
			});
	};

	getDirections = (e) => {
		e.preventDefault();
		const { details } = this.props;
		let sourceAddress = details.patientAddressLine1 + ' ' +
			details.patientCity + ', ' +
			details.patientState + ' ' +
			details.patientZip
		let destinationAddress =
			details.providerAddressLine1 + ' ' +
			details.providerAddressLine2 + ' ' +
			details.providerCity + ', ' +
			details.providerState + ' ' +
			details.providerZip;
		const baseUrl = 'https://www.google.com/maps/dir/?api=1';
		let fullUrl = baseUrl + '&origin=' + encodeURI(sourceAddress) + '&destination=' + encodeURI(destinationAddress);
		window.open(fullUrl);
	};

	handleToggleCollapse = (name) => {
		let collapse = { ...this.state.collapse };
		collapse[name] = !collapse[name];
		this.setState({ collapse });
	};

	handleCancelAppointmentClick = (e) => {
		e.preventDefault();
		this.props.clearAllErrors();
		const referenceId = this.props.details.referenceId;
		this.props.dispatch(routes.cancelAppointment(referenceId, this.props.config.instance.routePrefix));
	};

	resolveAdditionalInformation = () => {
		let result = [];
		result.push({
			firstName: this.props.details.providerFirstName,
			lastName: this.props.details.providerLastName,
			assetTag: '',
			informationForPatient: this.props.details.informationForConsumer,
			appointmentTypeName: this.props.details.appointmentTypeName,
			appointmentTypeInformationForPatient: this.props.details.informationForPatient,
			providerProfessionalDesignation: this.props.details.providerProfessionalDesignation,
		});
		return result;
	};

	handleBookAnother = (e) => {
		e.preventDefault();
		let prevPatientDemographics = {
			isBookAnotherAppointment: true,
			dateOfBirth: this.props.details.patientDateOfBirth,
			gender: this.props.details.patientGenderCode,
			firstName: this.props.details.patientFirstName,
			lastName: this.props.details.patientLastName,
			parentOrGuardianName: this.props.details.patientParentOrGuardianName,
			email: this.props.details.patientEmail,
			homePhone: this.props.details.patientHomePhone,
			mobilePhone: this.props.details.patientMobilePhone,
			memberId: null,
			address1: this.props.details.patientAddressLine1,
			address2: this.props.details.patientAddressLine2,
			city: this.props.details.patientCity,
			stateCode: this.props.details.patientState,
			zipCode: this.props.details.patientZip,
		};
		this.props.dispatch(setPatientDemographics(prevPatientDemographics));
		this.props.dispatch(clearDecisionSupport());
		this.props.dispatch(startWorkflow('bookAnotherAppointment'))
		this.props.dispatch(routes.bookAnotherAppointment(this.props.config.instance.routePrefix))
	};

	render() {
		const disableButtons = this.props.isLoading || this.state.isCancellingAppointment;
		const additionalInformation = this.resolveAdditionalInformation();

		return (
			<div className="view-content view-appointment-details-view">
				<ActiveErrors />
				{(this.state.areAppointmentDetailsLoading ||
					this.state.isCancellingAppointment ||
					this.state.isGettingIcsFile) && <LoadingIndicatorModal />}
				<div className="content-area">
					<aside className={'additional-content'}>
						<AppointmentSummaryPanel
							appointment={this.props.details} // TODO: change this
							disableButtons={disableButtons}
							getCalendar={this.getCalendar}
							getDirections={this.getDirections}
							handleToggleCollapse={this.handleToggleCollapse}
							isCancellingAppointment={this.state.isCancellingAppointment} // TODO: add this
							isCollapsed={this.state.collapse.appointmentSummary}
							isLoading={this.state.areAppointmentDetailsLoading}
							isMobile={this.state.isMobile}
							mode="BookedAppointment"
							name="appointmentSummary"
							onCancelAppointmentClick={this.handleCancelAppointmentClick} // TODO: add this
							providerFieldConfig={this.props.providerFieldConfig}
							summaryConfig={this.props.viewConfig.sections.appointmentSummary}
							fieldConfig={this.props.fieldConfig}
							handleBookAnother={this.handleBookAnother}
							endedInGuidedResponse={this.props.endedInGuidedResponse}
						/>
					</aside>
					<main className={'main-content'}>
						{this.props.labels.appointmentSuccessLabel.isVisible && (
							<div className="main-content-header">
								<Icon.IconCalendarCheck className="calendar-icon" />
								{this.props.labels.appointmentSuccessLabel.fieldLabel ? (
									<div
										className=""
										dangerouslySetInnerHTML={{
											__html: this.props.labels.appointmentSuccessLabel.fieldLabel,
										}}
									/>
								) : (
									<h2 className="">You've successfully scheduled your appointment.</h2>
								)}
							</div>
						)}
						<AdditionalInformationDetails
							appointments={additionalInformation}
							labels={this.props.labels}
							isCareOrder={false}
						/>
						{!this.state.isMobile && (
							<div className="section-row container-btn">
								{this.props.endedInGuidedResponse && this.props.viewConfig.sections.appointmentSummary.allowBookAnotherAppointment &&
									<Button.ButtonSecondary
										className="book-another-btn"
										onClick={this.handleBookAnother}
										disabled={disableButtons}
									>
										<span>Book Another Appointment</span>
									</Button.ButtonSecondary>
								}
								{this.props.viewConfig.sections.appointmentSummary.allowIcsFileDownload && (
									<Button.ButtonSecondary
										className="add-to-calendar-btn"
										onClick={this.getCalendar}
										disabled={disableButtons}
									>
										<span>Add to Calendar</span>
									</Button.ButtonSecondary>
								)}
								{this.props.viewConfig.sections.appointmentSummary.allowGetDirections && (
									<Button.ButtonSecondary
										className="get-directions-btn"
										onClick={this.getDirections}
										disabled={disableButtons}
									>
										Get Directions
									</Button.ButtonSecondary>
								)}
							</div>
						)}
					</main>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		viewConfig: {
			sections: {
				appointmentSummary: {
					allowIcsFileDownload: state.config.scheduling.enableIcsFileDownload,
					allowBookAnotherAppointment: state.config.scheduling.enableBookAnotherAppointment,
					allowChangeAppointment: state.config.scheduling.enableChangeTimeSlot,
					allowGetDirections: state.config.scheduling.enableGetDirections,
					showReasonForVisit: state.config.scheduling.showReasonForVisitApptDetails,
					enableCancelAppointment: state.config.scheduling.enableCancelAppointment,
				},
			},
		},
		auth: state.auth,
		config: state.config,
		details: state.appointment.details,
		icsFile: state.appointment.icsFile,
		isLoading: state.ajaxCallsInProgress > 0,
		isGroupNumberActive: state.config.system.isGroupNumberActive,
		isGroupNumberRequired: state.config.system.isGroupNumberRequired,
		isMemberIdActive: state.config.system.isMemberIdActive,
		isMemberIdRequired: state.config.system.isMemberIdRequired,
		labels: state.config.clientBranding,
		memberIdFieldLabel: state.config.system.memberIdFieldLabel,
		providerFieldConfig: state.config.provider,
		fieldConfig: state.config.fieldConfig,
		showInsuranceOnBookAppointment: state.config.scheduling.showInsuranceOnBookAppointment,
		showInsuranceOnAppointmentDetails: state.config.scheduling.showInsuranceOnAppointmentDetails,
		token: state.auth.token,
		useDecisionSupport: state.config.decisionSupport.useDecisionSupport,
		endedInGuidedResponse: state.decisionSupport.endedInGuidedResponse,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(
			{
				getAppointmentById,
				clearAllErrors,
				getPatientIcsFile,
			},
			dispatch,
		),
		dispatch,
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppointmentDetailsView));
