import React from 'react';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MhdLogo from './eh-logo-white.svg';
import {
	Col,
	Row,
} from 'reactstrap';
import './NavHeader.css';

export const NavHeader = (props) => {
	let headerFooterFontColor = props.auth.headerFooterFontColor ? props.auth.headerFooterFontColor : '#FFFFFF';
	let logo = props.auth.logo ? props.auth.logo : MhdLogo;

	return (
		<Row className='g-0'>
			<Col lg="6" className="col-6">
				<img src={logo} alt="Header Logo" className="cd2-branding-logo" onClick={props.onLogoClick}/>
			</Col>
			<Col lg="6" className="col-6 text-end secureBooking">
				<p style={{ color: headerFooterFontColor }}><FontAwesomeIcon icon={faLock} /> SECURE BOOKING</p>
			</Col>
		</Row>
	);
}

export default NavHeader;
